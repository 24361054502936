import { h } from 'preact'

import { Inline } from './blocks'

export const Acronym = ({ children, large = false }) => {
  return (
    <Inline fontSize={large ? '17px' : '15px'} textTransform="uppercase" letterSpacing="1px">
      {children}
    </Inline>
  )
}
