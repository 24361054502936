import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, Button, SpacerVertical, Input } from '@voicemachine/preact-ui'

import { fetchLabels, updateUser } from '../../../actions'
import { goBack } from '../../../router'

class AddLabel extends Component {
  state = {
    name: ''
  }

  componentDidMount() {
    fetchLabels()
  }

  save = async () => {
    if (
      await updateUser(this.props.userId, {
        labels: [...this.props.user.labels, this.state.name]
      })
    ) {
      goBack(`/users/${this.props.userId}`)
    }
  }

  addLabel = async (label) => {
    if (
      await updateUser(this.props.userId, {
        labels: [...this.props.user.labels, label]
      })
    ) {
      goBack(`/users/${this.props.userId}`)
    }
  }

  render() {
    const { user, isUpdatingUser, labels = [] } = this.props
    const { name } = this.state

    const currentLabels = user.labels

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        <Row alignItems="center" gap="10px" flexWrap="wrap" padding="0 20px">
          {labels.map((label) => {
            if (currentLabels.includes(label)) {
              return (
                <Row
                  alignItems="center"
                  border="solid 1px #ccc"
                  padding="0 10px"
                  borderRadius="20px"
                  gap="10px"
                  whiteSpace="nowrap"
                  userSelect="none"
                  background="#eee"
                >
                  <Block>{label}</Block>
                </Row>
              )
            }
            return (
              <Row
                alignItems="center"
                border="solid 1px #ccc"
                padding="0 10px"
                borderRadius="20px"
                gap="10px"
                whiteSpace="nowrap"
                cursor="pointer"
                userSelect="none"
                hoverBackground="#eee"
                props={{ onClick: () => this.addLabel(label) }}
              >
                <Block>{label}</Block>
              </Row>
            )
          })}
        </Row>
        <SpacerVertical />
        <Input label="New label" value={name} onChange={(name) => this.setState({ name })} />

        <SpacerVertical />
        <Row justifyContent="center">
          <Button
            primary
            loading={isUpdatingUser}
            disabled={name.trim() === ''}
            onClick={this.save}
          >
            Save
          </Button>
        </Row>
      </Block>
    )
  }
}

export default connect('user, isUpdatingUser, labels')(AddLabel)
