import { h, Component } from 'preact'

import { Block } from './blocks'
import { Loading } from './Loading'
import { Ripple } from './Ripple'

const getHeight = ({ large, small, tiny }) => {
  if (large) {
    return '63px'
  }
  if (small || tiny) {
    return '30px'
  }
  return '50px'
}

const getFontSize = ({ large, small, tiny }) => {
  if (large) {
    return '18px'
  }
  if (small) {
    return '12px'
  }
  if (tiny) {
    return '10px'
  }
  return '14px'
}

const getLineHeight = ({ large, small, tiny }) => {
  if (large) {
    return '20px'
  }
  if (small) {
    return '14px'
  }
  if (tiny) {
    return '12px'
  }
  return '16px'
}

const getLetterSpacing = ({ large, small, tiny }) => {
  if (large) {
    return '0.75px'
  }
  return '0.5px'
}

const getPadding = ({ large, small, tiny }) => {
  if (large) {
    return '0 25px'
  }
  if (small) {
    return '0 10px'
  }
  if (tiny) {
    return '0 5px'
  }
  return '0 15px'
}

const getColor = ({ primary, white, disabled, warning }) => {
  if (disabled) {
    if (white || primary || warning) {
      return 'rgba(255, 255, 255, 0.25)'
    }
    return 'rgba(0, 0, 0, 0.25)'
  }
  if (white || primary || warning) {
    return 'white'
  }
  return '#1f1f1f'
}

const getFill = ({ primary, white, disabled, warning }) => {
  if (disabled) {
    if (white || primary || warning) {
      return 'rgba(255, 255, 255, 0.20)'
    }
    return 'rgba(0, 0, 0, 0.20)'
  }
  if (white || primary || warning) {
    return '#fafafa'
  }
  return '#444'
}

const getBackground = ({ primary, white, warning, disabled, focus }) => {
  if (primary) {
    if (disabled) {
      return 'rgba(31, 31, 31, 0.25)'
    } else if (focus) {
      return '#3f3f3f'
    }
    return '#1f1f1f'
  }
  if (warning) {
    if (disabled) {
      return 'rgba(244, 49, 49, 0.25)'
    } else if (focus) {
      return '#FF5151'
    }
    return '#F43131'
  }
  if (focus) {
    if (white) {
      return 'rgba(0, 0, 0, 0.1)'
    }
    return 'rgba(234, 90, 153, 0.075)'
  }
  return 'none'
}

export class Button extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = () => {
    this.doAction()
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doAction(false)
    }
  }

  handleTouchStart = (e) => {
    if (this.props.disabled || this.props.loading) {
      return
    }

    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const touch = e.touches[0]
      var rect = this.base.getBoundingClientRect()
      const x = touch.pageX - rect.left
      const y = touch.pageY - rect.top
      const width = rect.width
      const height = rect.height
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    //e.preventDefault()
    if (this.state.pressed) {
      this.doAction()
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  getDelay = () => {
    if (this.props.delay !== undefined) {
      return this.props.delay
    }
    return this.state.ripples.length > 0 ? 300 : 0
  }

  doAction = (removeFocus = false) => {
    console.log('doAction')
    if (!this.props.disabled && !this.props.loading && this.props.onClick) {
      setTimeout(() => {
        this.props.onClick()
      }, this.getDelay())
    }
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  render() {
    const { focus, ripples } = this.state
    const {
      type,
      children,
      disabled,
      loading,
      primary,
      warning,
      white,
      large,
      small,
      tiny,
      onClick,
      ...props
    } = this.props

    const clickable = !disabled && !loading
    const webkitTapHighlightColor = disabled ? 'none' : primary ? 'rgba(255, 255, 255, 0.25)' : ''
    const rippleColor = primary || warning || white ? 'rgba(255, 255, 255, 0.5)' : undefined
    const fill = getFill({ primary, white, disabled, warning })
    const isTouchDevice = 'ontouchstart' in window

    return (
      <Block
        component="button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        disabled={disabled}
        onClick={clickable && !isTouchDevice ? this.handleClick : null}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={(elem) => (this.touchElem = elem)}
        // onTouchStart={this.handleTouchStart}
        // onTouchMove={this.handleTouchMove}
        // onTouchCancel={this.handleTouchCancel}
        // onTouchEnd={this.handleTouchEnd}
        boxSizing="border-box"
        height={getHeight({ large, small, tiny })}
        minWidth="100px"
        padding={getPadding({ large, small, tiny })}
        fontSize={getFontSize({ large, small, tiny })}
        lineHeight={getLineHeight({ large, small, tiny })}
        fontWeight={primary ? 'bold' : 'normal'}
        textTransform="uppercase"
        letterSpacing={getLetterSpacing({ large, small, tiny })}
        outline="none"
        userSelect="none"
        WebkitUserSelect="none"
        borderRadius="3px"
        border={disabled ? 'solid 1px #ccc' : 'none'}
        cursor={disabled ? 'not-allowed' : clickable ? 'pointer' : 'default'}
        color={getColor({ primary, white, disabled, warning })}
        fill={fill}
        background={getBackground({ primary, white, warning, disabled, focus })}
        webkitTapHighlightColor={webkitTapHighlightColor}
        boxShadow={disabled ? 'none' : '0 1px 3px 0 rgba(0, 0, 0, 0.25)'}
        position="relative"
        overflow="hidden"
        props={{ type }}
        {...props}
      >
        {ripples.map((r) => (
          <Ripple {...r} onDone={this.rippleDone} color={rippleColor} />
        ))}
        {loading && <Loading stroke={fill} />}
        {!loading && children}
      </Block>
    )
  }
}
