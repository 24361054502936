import { h } from 'preact'
import { Block } from './blocks'
import { SpacerVertical } from './spacers'

export const InputInfo = ({ children, large = false }) => {
  if (!children || children.length === 0) {
    return null
  }
  return (
    <Block>
      <SpacerVertical tiny />
      <Block color="#ccc" fontSize={large ? '15px' : '12px'} lineHeight={large ? '18px' : '14px'}>
        {children}
      </Block>
    </Block>
  )
}
