import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import dateFormat from 'dateformat'
import {
  Row,
  Block,
  SpacerVertical,
  SeparatorVertical,
  SpacerHorizontal,
  ButtonTertiary,
  DeleteIcon,
  AssignmentIcon,
  EuroIcon,
  ReceiptIcon,
  Section,
  DownloadIcon
} from '@voicemachine/preact-ui'

import { deleteFile, deleteAudio } from '../../../actions'

import Missing from '../../Missing'

import FileIcon from './FileIcon'

const formatDate = (date) => {
  return dateFormat(date, 'yyyy-mm-dd HH:MM')
}

const formatOwner = (userId, project) => {
  if (userId === project.client.id) {
    return project.client.name
  }
  for (let voice of project.voices) {
    if (userId === voice.id) {
      return voice.name
    }
  }
  return null
}

class Files extends Component {
  confirmDeleteFile = (file) => {
    if (confirm('Are you sure?')) {
      deleteFile(this.props.projectId, file.id)
    }
  }

  confirmDeleteAudio = (file) => {
    if (confirm('Are you sure?')) {
      deleteAudio(this.props.projectId, file.id)
    }
  }

  render() {
    const { project } = this.props
    const files = project.files || []
    const audio = project.audio || []

    return (
      <Block flex="1" height="100%" background="#FAFAFA" overflow="auto">
        <Section>Agreements, Receipt & Self Billing</Section>
        {!project.isAccepted && <Missing>No files</Missing>}
        {project.isAccepted && (
          <Block>
            {project.clientAgreementUri && (
              <Block padding="0 30px">
                <Row padding="20px 0">
                  <AssignmentIcon />
                  <SpacerHorizontal />
                  <Block
                    flex="1"
                    cursor="pointer"
                    onClick={() => window.open(project.clientAgreementUri)}
                  >
                    <Block>Client Agreement</Block>
                  </Block>
                </Row>
              </Block>
            )}
            {project.voiceAgreementUri && (
              <Block padding="0 30px">
                <Row padding="20px 0">
                  <AssignmentIcon />
                  <SpacerHorizontal />
                  <Block
                    flex="1"
                    cursor="pointer"
                    onClick={() => window.open(project.voiceAgreementUri)}
                  >
                    <Block>Voice Agreement</Block>
                  </Block>
                </Row>
              </Block>
            )}
            {project.receiptUri && (
              <Block padding="0 30px">
                <Row padding="20px 0">
                  <ReceiptIcon />
                  <SpacerHorizontal />
                  <Block flex="1" cursor="pointer" onClick={() => window.open(project.receiptUri)}>
                    <Block>Receipt</Block>
                  </Block>
                </Row>
              </Block>
            )}
            {project.selfBillingUri && (
              <Block padding="0 30px">
                <Row padding="20px 0">
                  <EuroIcon />
                  <SpacerHorizontal />
                  <Block
                    flex="1"
                    cursor="pointer"
                    onClick={() => window.open(project.selfBillingUri)}
                  >
                    <Block>Self Billing</Block>
                  </Block>
                </Row>
              </Block>
            )}
            {project.invoiceInfoUri && (
              <Block padding="0 30px">
                <Row padding="20px 0">
                  <EuroIcon />
                  <SpacerHorizontal />
                  <Block
                    flex="1"
                    cursor="pointer"
                    onClick={() => window.open(project.invoiceInfoUri)}
                  >
                    <Block>Invoice info</Block>
                  </Block>
                </Row>
              </Block>
            )}
          </Block>
        )}

        <SpacerVertical />
        <Section>Audio files</Section>
        {audio.length === 0 && <Missing>No audio files</Missing>}
        {audio.map((audio, index) => {
          return (
            <Block padding="0 30px">
              <Row padding="20px 0">
                <FileIcon size="32px" mime="audio/*" />
                <SpacerHorizontal />
                <Block flex="1" cursor="pointer" onClick={() => window.open(audio.processedUri)}>
                  <Block>{audio.name || <em>No name</em>}</Block>
                  <Block fontSize="12px" color="#666">
                    {formatOwner(audio.user.id, project)} · {formatDate(audio.created)} ·{' '}
                    {audio.status}
                  </Block>
                </Block>
                <ButtonTertiary icon={<DownloadIcon />} onClick={() => window.open(audio.uri)}>
                  Unprocessed
                </ButtonTertiary>
                <ButtonTertiary
                  icon={<DeleteIcon />}
                  onClick={() => this.confirmDeleteAudio(audio)}
                />
              </Row>
              {index < files.length - 1 && <SeparatorVertical />}
            </Block>
          )
        })}

        <SpacerVertical />
        <Section>Other files</Section>
        {files.length === 0 && <Missing>No other files</Missing>}
        {files.map((file, index) => {
          return (
            <Block padding="0 30px">
              <Row padding="20px 0">
                <FileIcon size="32px" mime={file.mime} />
                <SpacerHorizontal />
                <Block flex="1" cursor="pointer" onClick={() => window.open(file.uri)}>
                  <Block>{file.name || <em>No name</em>}</Block>
                  <Block fontSize="12px" color="#666">
                    {formatOwner(project.client.id, project)} · {formatDate(file.created)} ·{' '}
                    {file.status}
                  </Block>
                </Block>
                <ButtonTertiary
                  icon={<DeleteIcon />}
                  onClick={() => this.confirmDeleteFile(file)}
                />
              </Row>
              {index < files.length - 1 && <SeparatorVertical />}
            </Block>
          )
        })}
        <SpacerVertical />
      </Block>
    )
  }
}

export default connect('project')(Files)
