import { h } from 'preact'
import { Block } from '@voicemachine/preact-ui'

export const Table = ({ children, ...props }) => (
  <Block component="table" display="table" width="100%" borderCollapse="collapse" {...props}>
    {children}
  </Block>
)

export const TableRow = ({ children, ...props }) => (
  <Block component="tr" display="table-row" {...props}>
    {children}
  </Block>
)

export const TableData = ({ children, ...props }) => (
  <Block component="td" display="table-cell" padding="10px" {...props}>
    {children}
  </Block>
)

export const TableHeader = ({ children, ...props }) => (
  <Block component="th" display="table-cell" textAlign="left" padding="10px" {...props}>
    {children}
  </Block>
)
