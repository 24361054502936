import { h } from 'preact'
import { connect } from 'unistore/preact'

import { Col, Block } from '@voicemachine/preact-ui'
import { route } from '../../../router'

const MenuItem = ({ path, label }) => {
  const isActive = window.location.pathname.startsWith(path)
  const handleClick = () => route(path)
  return (
    <Block
      padding="10px 20px"
      fontWeight={isActive ? 'bold' : ''}
      cursor={isActive ? '' : 'pointer'}
      onClick={isActive ? null : handleClick}
    >
      {label}
    </Block>
  )
}

const UserMenu = ({ user }) => {
  const items = [
    { path: `/users/${user.id}/details`, label: 'User' },
    { path: `/users/${user.id}/notifications`, label: 'Notifications' },
    { path: `/users/${user.id}/projects`, label: 'Projects' }
  ]

  if (user.isVoice) {
    items.push(
      { path: `/users/${user.id}/studio_demo`, label: 'Studio demo' },
      { path: `/users/${user.id}/voice_demos`, label: 'Voice demos' }
    )
  }

  return (
    <Col padding="10px" width="200px">
      {items.map((item) => (
        <MenuItem {...item} />
      ))}
    </Col>
  )
}

export default connect('user')(UserMenu)
