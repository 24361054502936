import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Block,
  Button,
  InlineBlock,
  Row,
  SpacerVertical,
  ToggleSwitch
} from '@voicemachine/preact-ui'
import dateFormat from 'dateformat'

import {
  createProjectTemplate,
  deleteProjectTemplate,
  fetchProjectTemplates,
  setProjectTemplateStatus
} from '../../actions'
import { route } from '../../router'

import { Table, TableData, TableHeader, TableRow } from '../Table'
import Avatar from '../Avatar'

const byName = (a, b) => a.name.localeCompare(b.name)

const formatDuration = (date) => {
  if (!date) {
    return ''
  }
  const now = new Date()
  const then = new Date(date)
  if (now.getUTCDate() !== then.getUTCDate()) {
    return dateFormat(date, 'yyyy-mm-dd')
  }
  const duration = now - then
  const seconds = Math.round(duration / 1000)
  if (seconds < 60) {
    return `just now`
  }
  const minutes = Math.round(seconds / 60)
  if (minutes < 60) {
    return `${minutes} min ago`
  }
  const hours = Math.round(minutes / 60)
  if (hours < 3) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  }
  return 'today ' + dateFormat(date, 'HH:MM')
}

class ListProjectTemplates extends Component {
  componentDidMount() {
    fetchProjectTemplates()
  }

  async handleCreate() {
    const template = await createProjectTemplate({ name: 'New template' })
    if (template) {
      route(`/project-templates/${template.id}`)
    }
  }

  async delete(templateId) {
    if (confirm('Are you sure?')) {
      deleteProjectTemplate(templateId)
    }
  }

  async toggleStatus(projectTemplate) {
    const status = projectTemplate.status === 'enabled' ? 'disabled' : 'enabled'
    setProjectTemplateStatus(projectTemplate.id, status)
  }

  render() {
    const { projectTemplates = [], isCreatingProjectTemplate } = this.props
    return (
      <Block padding="10px">
        <Table width="auto">
          <TableRow>
            <TableHeader textAlign="right">Seq</TableHeader>
            <TableHeader textAlign="left">Name</TableHeader>
            <TableHeader textAlign="center">Enabled</TableHeader>
            <TableHeader textAlign="right">Customer price</TableHeader>
            <TableHeader textAlign="right">Voice fee</TableHeader>
            <TableHeader textAlign="center">Clients</TableHeader>
            <TableHeader textAlign="center">Voices</TableHeader>
            <TableHeader textAlign="center">Created</TableHeader>
            <TableHeader textAlign="center">Modified</TableHeader>
            <TableHeader textAlign="center">Edit</TableHeader>
            <TableHeader textAlign="center">Delete</TableHeader>
          </TableRow>
          {projectTemplates.map((projectTemplate) => {
            const allowedClients = projectTemplate.allowedClients.sort(byName)
            const allowedVoices = projectTemplate.allowedVoices.sort(byName)
            return (
              <TableRow hoverBackground="#fafafa">
                <TableData textAlign="right">{projectTemplate.sequence}</TableData>
                <TableData textAlign="left">
                  <a
                    href=""
                    style="padding:10px"
                    onClick={(e) => {
                      e.preventDefault()
                      route(`/project-templates/${projectTemplate.id}`)
                    }}
                  >
                    {projectTemplate.name}
                  </a>
                </TableData>
                <TableData textAlign="center">
                  <ToggleSwitch
                    on={projectTemplate.status === 'enabled'}
                    onChange={() => this.toggleStatus(projectTemplate)}
                  />
                </TableData>
                <TableData textAlign="right">{projectTemplate.customerPrice}</TableData>
                <TableData textAlign="right">{projectTemplate.voiceFee}</TableData>
                <TableData>
                  <Row alignItems="center" justifyContent="center">
                    {allowedClients.slice(0, 3).map((user) => {
                      return <Avatar user={user} />
                    })}
                    {allowedClients.length > 3 && <Block>+{allowedClients.length - 3}</Block>}
                    {allowedClients.length === 0 && <InlineBlock>All clients</InlineBlock>}
                  </Row>
                </TableData>
                <TableData>
                  <Row alignItems="center" justifyContent="center">
                    {allowedVoices.slice(0, 3).map((user) => {
                      return <Avatar user={user} />
                    })}
                    {allowedVoices.length > 3 && <Block>+{allowedVoices.length - 3}</Block>}
                    {allowedVoices.length === 0 && <InlineBlock>All voices</InlineBlock>}
                  </Row>
                </TableData>
                <TableData textAlign="center">{formatDuration(projectTemplate.created)}</TableData>
                <TableData textAlign="center">
                  {formatDuration(projectTemplate.lastModified)}
                </TableData>
                <TableData textAlign="center">
                  <Block
                    cursor="pointer"
                    onClick={() => route(`/project-templates/${projectTemplate.id}`)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                    </svg>
                  </Block>
                </TableData>
                <TableData textAlign="center">
                  <Block cursor="pointer" onClick={() => this.delete(projectTemplate.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                    </svg>
                  </Block>
                </TableData>
              </TableRow>
            )
          })}
        </Table>
        <SpacerVertical />
        <Button onClick={this.handleCreate} loading={isCreatingProjectTemplate}>
          + New template
        </Button>
      </Block>
    )
  }
}

export default connect('projectTemplates, isFetchingProjectTemplates, isCreatingProjectTemplate')(
  ListProjectTemplates
)
