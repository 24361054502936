import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import dateFormat from 'dateformat'
import { getName } from 'country-list'

import { fetchManualInvoices, updateManualInvoice } from '../actions'
import { Button, Input, SpacerHorizontal, SpacerVertical } from '@voicemachine/preact-ui'
import { goBack } from '../router'

const formatCountry = (code) => getName(code)

const formatEuro = (value) => {
  return '€' + parseFloat(value).toFixed(2)
}

class ManualInvoice extends Component {
  componentDidMount() {
    fetchManualInvoices()

    if (this.props.manualInvoices) {
      const invoice = this.props.manualInvoices.find(
        (invoice) => invoice.id === this.props.invoiceId
      )
      this.setState({ invoiceNumber: invoice.invoiceNumber })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.manualInvoices && prevProps.manualInvoices !== this.props.manualInvoices) {
      const invoice = this.props.manualInvoices.find(
        (invoice) => invoice.id === this.props.invoiceId
      )

      if (invoice.invoiceNumber !== this.state.invoiceNumber) {
        this.setState({ invoiceNumber: invoice.invoiceNumber })
      }
    }
  }

  save = async () => {
    if (
      await updateManualInvoice(this.props.invoiceId, { invoiceNumber: this.state.invoiceNumber })
    ) {
      goBack('/manual-invoices')
    }
  }

  render() {
    const invoice = this.props.manualInvoices
      ? this.props.manualInvoices.find((invoice) => invoice.id === this.props.invoiceId)
      : null

    if (!invoice) {
      return null
    }

    return (
      <Block padding="20px">
        <Block>
          {invoice.project.name} {invoice.project.id.substring(0, 5)}
        </Block>
        <Block>Purchase order number / reference: {invoice.project.purchaseOrder}</Block>
        <Block>Approved date: {dateFormat(invoice.project.approvedDate, 'yyyy-mm-dd')}</Block>
        <Block>
          Client: {invoice.user.name} – {invoice.user.email}
        </Block>
        <br />
        <Block>{invoice.user.companyName}</Block>
        <Block>{invoice.user.companyAddressLine1}</Block>
        <Block>{invoice.user.companyAddressLine2}</Block>
        <Block>
          {invoice.user.companyPostalCode} {invoice.user.companyCity}
        </Block>
        <Block>{formatCountry(invoice.user.companyCountry)}</Block>
        <Block>VAT {invoice.user.companyVatId}</Block>
        <Block>TAX {invoice.user.companyTaxId}</Block>
        <br />
        <Block>Invoice email: {invoice.user.invoiceEmail}</Block>
        <br />
        <table>
          <tr>
            <td>Base cost</td>
            <td style="text-align: right">
              {formatEuro(invoice.project.customerPriceDetails.price)}
            </td>
          </tr>
          {invoice.project.customerPriceDetails.extras.map((extra) => {
            return (
              <tr>
                <td style="padding-right: 10px">{extra.label}</td>
                <td style="text-align: right">{formatEuro(extra.price)}</td>
              </tr>
            )
          })}
          {invoice.project.customerPriceDetails.discount > 0 && (
            <tr>
              <td style="padding-right: 10px">Discount</td>
              <td style="text-align: right">
                -{formatEuro(invoice.project.customerPriceDetails.discount)}
              </td>
            </tr>
          )}
          <tr>
            <td colspan="2">
              <hr />
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td style="text-align: right">
              {formatEuro(invoice.project.customerPriceDetails.total)}
            </td>
          </tr>
          <tr>
            <td>VAT</td>
            <td style="text-align: right">€{invoice.vat}</td>
          </tr>
          <tr>
            <td colspan="2">
              <hr />
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold">Total incl VAT</td>
            <td style="text-align: right; font-weight: bold">€{invoice.amountInclVat}</td>
          </tr>
        </table>
        <SpacerVertical />
        <Block marginLeft="-30px" maxWidth="400px">
          <Input
            label="Invoice no"
            value={this.state.invoiceNumber}
            onChange={(invoiceNumber) => this.setState({ invoiceNumber })}
          />
        </Block>
        <SpacerVertical />
        <Row alignItems="center">
          <Button loading={this.props.isUpdatingManualInvoice} onClick={this.save}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button onClick={() => goBack('/manual-invoices')}>Close</Button>
        </Row>
      </Block>
    )
  }
}

export default connect('manualInvoices, isFetchingManualInvoices, isUpdatingManualInvoice')(
  ManualInvoice
)
