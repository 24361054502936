import { h } from 'preact'
import { Block as JsxBlock } from 'jsxstyle/preact'

const Div = ({
  component = 'div',
  disabled,
  value,
  ref,
  href,
  target,
  onClick,
  onChange,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  onTouchCancel,
  onScroll,
  onFocus,
  onBlur,
  tabIndex,
  onKeyPress,
  onKeyDown,
  children,
  role,
  props,
  ...style
}) => (
  <JsxBlock
    component={component}
    {...style}
    props={{
      disabled,
      value,
      ref,
      href,
      target,
      onClick,
      onChange,
      onMouseDown,
      onMouseUp,
      onMouseMove,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onTouchCancel,
      onScroll,
      onFocus,
      onBlur,
      tabIndex,
      onKeyPress,
      onKeyDown,
      role,
      ...props
    }}
  >
    {children}
  </JsxBlock>
)

export const Block = (props) => <Div display="block" {...props} />
export const InlineBlock = (props) => <Div display="inline-block" {...props} />
export const Inline = (props) => <Div display="inline" {...props} />

export const Row = (props) => {
  const direction = props.reverse ? 'row-reverse' : 'row'
  return <Div display="flex" flexDirection={direction} alignItems="center" {...props} />
}

export const Col = (props) => <Div display="flex" flexDirection="column" {...props} />
