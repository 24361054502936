import { h, Component } from 'preact'
import { Row } from './blocks'
import { Ripple } from './Ripple'

export class IconButton extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doAction({ event: e, removeFocus: true })
    }
  }

  handleClick = (e) => {
    this.doAction({ event: e })
  }

  handleTouchStart = (e) => {
    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const size = this.getSize()
      const x = size / 2
      const y = size / 2
      const width = size
      const height = size
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    e.preventDefault()
    if (this.state.pressed) {
      this.doAction({ event: e })
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  doAction = ({ event, removeFocus = false }) => {
    if (this.state.ripples.length > 0) {
      setTimeout(() => {
        this.props.onClick(event)
      }, 150)
    } else {
      this.props.onClick(event)
    }
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  getSize = () => {
    const { large, huge } = this.props
    return huge ? 64 : large ? 52 : 45
  }

  render() {
    const { focus, ripples } = this.state
    const { icon: Icon, onClick, white, type = 'button', ...props } = this.props

    const size = this.getSize() + 'px'

    const background = focus
      ? white
        ? 'rgba(0, 0, 0, 0.05)'
        : 'rgba(234, 90, 153, 0.075)'
      : 'none'

    const rippleColor = white ? 'rgba(255, 255, 255, 0.5)' : undefined

    return (
      <Row
        component="button"
        border="none"
        outline="none"
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={(elem) => (this.touchElem = elem)}
        //onTouchStart={(this.handleTouchStart, { passive: true })}
        // onTouchMove={this.handleTouchMove}
        // onTouchCancel={this.handleTouchCancel}
        // onTouchEnd={this.handleTouchEnd}
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        props={{ type }}
        {...props}
      >
        <Row
          alignItems="center"
          justifyContent="center"
          width={size}
          height={size}
          borderRadius="50%"
          background={background}
          position="relative"
          overflow="hidden"
        >
          {ripples.map((r) => (
            <Ripple {...r} onDone={this.rippleDone} time={200} color={rippleColor} />
          ))}
          {Icon}
        </Row>
      </Row>
    )
  }
}
