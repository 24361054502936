import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Col, Block, SpacerVertical } from '@voicemachine/preact-ui'

import { route } from '../../../router'

import UserInfo from '../../UserInfo'
import Missing from '../../Missing'

const sortVoices = (a, b) => {
  if (a.isAssigned && !b.isAssigned) {
    return -1
  }
  if (!a.isAssigned && b.isAssigned) {
    return 1
  }
  return a.name.localeCompare(b.name)
}

class Voices extends Component {
  render() {
    const voices = this.props.project ? this.props.project.voices.sort(sortVoices) : []
    const hasAssigned = voices.some((voice) => voice.isAssigned)
    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        {voices.length === 0 && <Missing>No voices</Missing>}
        <Col>
          {voices.map((voice) => {
            const isAssigned = voice.isAssigned
            return (
              <UserInfo
                user={voice}
                gray={hasAssigned && !isAssigned}
                onClick={() => route(`/users/${voice.id}/profile`)}
              />
            )
          })}
        </Col>
      </Block>
    )
  }
}

export default connect('project')(Voices)
