import { h, Component } from 'preact'
import { Block } from './blocks'
import { Ripple } from './Ripple'

export class SimpleButton extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = () => {
    this.doAction()
  }

  doAction = (removeFocus = false) => {
    if (this.state.ripples.length > 0) {
      setTimeout(() => {
        this.props.onClick()
      }, 300)
    } else {
      this.props.onClick()
    }
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doAction(false)
    }
  }

  handleTouchStart = (e) => {
    if (this.props.disabled || !this.props.onClick) {
      return
    }

    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const touch = e.touches[0]
      var rect = this.base.getBoundingClientRect()
      const x = touch.pageX - rect.left
      const y = touch.pageY - rect.top
      const width = rect.width
      const height = rect.height
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    e.preventDefault()
    if (this.state.pressed) {
      this.doAction()
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  render() {
    const { focus, ripples } = this.state
    const {
      flexDirection = 'row',
      alignItems = 'center',
      justifyContent = 'center',
      children,
      onClick,
      focusable = true,
      ...props
    } = this.props

    return (
      <Block
        component="button"
        border="none"
        outline="none"
        position="relative"
        overflow="hidden"
        onClick={this.handleClick}
        onFocus={focusable ? this.handleFocus : undefined}
        ref={(elem) => (this.touchElem = elem)}
        // onTouchStart={this.handleTouchStart}
        // onTouchMove={this.handleTouchMove}
        // onTouchCancel={this.handleTouchCancel}
        // onTouchEnd={this.handleTouchEnd}
        tabIndex={focusable ? '0' : '-1'}
        onBlur={this.handleBlur}
        display="flex"
        flexDirection={flexDirection}
        alignItems={alignItems}
        justifyContent={justifyContent}
        cursor="pointer"
        background={focus ? 'rgba(234, 90, 153, 0.075)' : 'none'}
        {...props}
      >
        {ripples.map((r) => (
          <Ripple {...r} onDone={this.rippleDone} />
        ))}
        {children}
      </Block>
    )
  }
}
