import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, SpacerVertical, SeparatorVertical, Section } from '@voicemachine/preact-ui'

import { route } from '../../../router'

import Missing from '../../Missing'

const Project = ({ project }) => (
  <Row padding="20px 0" cursor="pointer" onClick={() => route(`/projects/${project.id}`)}>
    <Block>{project.name}</Block>
  </Row>
)

class Projects extends Component {
  render() {
    const { user } = this.props
    const projects = user.projects || []

    const clientProjects = projects.filter((p) => p.client.id === user.id)
    const voiceProjects = projects.filter((p) => p.client.id !== user.id)

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        {projects.length === 0 && <Missing>No projects</Missing>}
        {clientProjects.length > 0 && (
          <Block>
            <Section>Client</Section>
            {clientProjects.map((project, index) => {
              return (
                <Block padding="0 30px">
                  <Project project={project} />
                  {index < projects.length - 1 && <SeparatorVertical />}
                </Block>
              )
            })}
            <SpacerVertical />
          </Block>
        )}
        {voiceProjects.length > 0 && (
          <Block>
            <Section>Voice</Section>
            {voiceProjects.map((project, index) => {
              return (
                <Block padding="0 30px">
                  <Project project={project} />
                  {index < projects.length - 1 && <SeparatorVertical />}
                </Block>
              )
            })}
            <SpacerVertical />
          </Block>
        )}
      </Block>
    )
  }
}

export default connect('user')(Projects)
