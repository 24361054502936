import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Col, Block } from '@voicemachine/preact-ui'

import { fetchStats } from '../../actions'
import { route } from '../../router'

const Label = ({ children }) => <Block flex="1">{children}</Block>

const Value = ({ children, ...props }) => (
  <Block width="50px" padding="5px" textAlign="right" {...props}>
    {children}
  </Block>
)

class Dashboard extends Component {
  componentWillMount() {
    fetchStats()
  }

  render() {
    const { stats } = this.props
    if (!stats) {
      return null
    }
    return (
      <Block padding="20px">
        <h1>Users</h1>
        <Col width="200px">
          <Row cursor="pointer" onClick={() => route('/users?orderBy=name')}>
            <Label>Clients:</Label>
            <Value>{stats.numClients}</Value>
          </Row>
          <Row cursor="pointer">
            <Label>Pending voices:</Label>
            <Value onClick={() => route('/users?voiceStatus=pending')}>
              {stats.numPendingVoices}
            </Value>
          </Row>
          <Row cursor="pointer">
            <Label>Published voices:</Label>
            <Value onClick={() => route('/users?voiceStatus=published')}>
              {stats.numApprovedVoices}
            </Value>
          </Row>
        </Col>
        <h1>Projects</h1>
        <Col width="200px">
          <Row cursor="pointer" onClick={() => route('/projects?orderBy=name')}>
            <Label>Total:</Label>
            <Value>{stats.numProjects}</Value>
          </Row>
          <Row cursor="pointer" onClick={() => route('/projects?status=approved')}>
            <Label>Approved:</Label>
            <Value>{stats.numApprovedProjects || 0}</Value>
          </Row>
        </Col>
      </Block>
    )
  }
}

export default connect('stats')(Dashboard)
