import { h, Component } from 'preact'
import {
  Block,
  Button,
  Col,
  Input,
  Row,
  SpacerHorizontal,
  ToggleSwitch
} from '@voicemachine/preact-ui'

import { goBack } from '../../router'
import { get } from '../../api'

import Avatar from '../Avatar'

class SelectUsers extends Component {
  componentDidMount() {
    this.setState({ selected: this.props.selected.map((user) => user.id) })
    const url =
      this.props.userType === 'client'
        ? `/users?status=verified&voiceStatus=client`
        : `/users?status=verified&voiceStatus=published`

    get(url).then(({ data: users }) => this.setState({ users }))
  }

  toggleSelected = (userId) => {
    const { selected = [] } = this.state
    if (selected.includes(userId)) {
      this.setState({ selected: selected.filter((id) => id !== userId) })
    } else {
      this.setState({ selected: [...selected, userId] })
    }
  }

  handleSave = () => {
    const { templateId, onChange } = this.props
    const { users, selected = [] } = this.state
    const selectedUsers = users.filter((user) => selected.includes(user.id))
    onChange(selectedUsers)
    goBack(`/project-templates/${templateId}`)
  }

  toggleAll(select) {
    if (select) {
      const { users = [], query = '' } = this.state
      const regex = new RegExp(`\\b${query}`, 'i')
      const filteredUsers = users.filter((user) => {
        return query.trim() === '' || regex.test(user.name) || regex.test(user.companyName)
      })
      this.setState({ selected: filteredUsers.map((user) => user.id) })
    } else {
      this.setState({ selected: [] })
    }
  }

  render() {
    const { templateId } = this.props
    const { users = [], selected = [], query = '' } = this.state

    const regex = new RegExp(`\\b${query}`, 'i')
    const filteredUsers = users.filter((user) => {
      return query.trim() === '' || regex.test(user.name) || regex.test(user.companyName)
    })

    const allSelected =
      filteredUsers.length > 0 && filteredUsers.every((user) => selected.includes(user.id))

    return (
      <Col
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.3)"
      >
        <Col width="500px" height="700px" maxHeight="100%" maxWidth="100%" background="white">
          <Block paddingTop="20px" borderBottom="solid 1px #eee">
            <Input label="Filter" value={query} onChange={(query) => this.setState({ query })} />
            <ToggleSwitch
              label="Select all"
              on={allSelected}
              onChange={() => this.toggleAll(!allSelected)}
            />
          </Block>
          <Block flex="1" overflow="auto">
            {users.length === 0 && <Block padding="20px">Loading...</Block>}
            {filteredUsers.map((user) => {
              return (
                <Row alignItems="center" height="60px">
                  <ToggleSwitch
                    on={selected.includes(user.id)}
                    onChange={() => this.toggleSelected(user.id)}
                  />
                  <Avatar user={user} />
                  <SpacerHorizontal small />
                  <Block>
                    <Block>{user.name}</Block>
                    <Block fontSize="12px" color="#555">
                      {user.companyName}
                    </Block>
                  </Block>
                </Row>
              )
            })}
          </Block>
          <Row alignItems="center" padding="20px" borderTop="solid 1px #eee">
            <Button small onClick={this.handleSave}>
              Select
            </Button>
            <SpacerHorizontal small />
            <Button small onClick={() => goBack(`/project-templates/${templateId}`)}>
              Cancel
            </Button>
          </Row>
        </Col>
      </Col>
    )
  }
}

export default SelectUsers
