import {
  Block,
  Button,
  Input,
  Row,
  SpacerHorizontal,
  SpacerVertical,
  ToggleSwitch
} from '@voicemachine/preact-ui'
import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { createApiKey } from '../../actions'

export const allScopes = [
  ['list-users', 'list-user', 'create-user', 'update-user', 'delete-user'],
  ['list-projects', 'list-project', 'create-project', 'update-project', 'delete-project'],
  [
    'list-stats',
    'charge-accepted',
    'payout-approved',
    'archive-approved',
    'auto-approve',
    'send-has-unread-email',
    'send-pickup-reminder',
    'send-auto-approve-reminder',
    'update-rank',
  ],
  ['create-magic-link', 'consume-magic-link']
]

class CreateApiKey extends Component {
  toggleScope = (scope) => {
    const { scopes = [] } = this.state
    if (scopes.includes(scope)) {
      this.setState({ scopes: scopes.filter((s) => s !== scope) })
    } else {
      this.setState({ scopes: [...scopes, scope] })
    }
  }

  save = async () => {
    if (await createApiKey(this.state)) {
      history.back()
    }
  }

  render() {
    const { comment = '', scopes = [] } = this.state
    return (
      <Block padding="10px 0">
        <Input label="Comment" value={comment} onChange={(comment) => this.setState({ comment })} />
        <SpacerVertical />
        {allScopes.map((group) => {
          return (
            <Block>
              {group.map((scope) => {
                return (
                  <ToggleSwitch
                    label={scope}
                    on={scopes.includes(scope)}
                    onChange={() => this.toggleScope(scope)}
                  />
                )
              })}
              <SpacerVertical />
            </Block>
          )
        })}
        <Row padding="10px">
          <Button onClick={this.save}>Save</Button>
          <SpacerHorizontal />
          <Button onClick={() => history.back()}>Cancel</Button>
        </Row>
      </Block>
    )
  }
}

export default connect('isCreatingApiKey')(CreateApiKey)
