import { h, Component } from 'preact'

import { Block, Row } from './blocks'
import { DownIcon } from './icons'
import { Label } from './Label'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'

export class Select extends Component {
  state = {
    focus: false
  }

  handleFocus = () => {
    this.setState({ focus: true })
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  handleBlur = () => {
    this.setState({ focus: false })
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  handleClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e.target.value)
    }
  }

  render() {
    const {
      label,
      placeholder,
      value,
      infoText,
      errorText,
      maxWidth,
      options = [],
      disabled,
      large = false
    } = this.props
    const { focus } = this.state
    const selectedOption = options.find(
      (option) => option.value === value && option.label !== placeholder
    )

    const fontSize = large ? '20px' : '16px'
    const lineHeight = large ? '24px' : '18px'

    return (
      <Block
        width="calc(100% - 60px)"
        maxWidth={maxWidth ? maxWidth : 'none'}
        margin="0 30px"
        boxSizing="border-box"
      >
        <Block component="label" position="relative">
          {label && (
            <Label large={large} error={errorText}>
              {label}
            </Label>
          )}
          <Row alignItems="center" height={large ? '40px' : '35px'}>
            <Block
              component="select"
              ref={(elem) => (this.select = elem)}
              outline="none"
              flex="1"
              padding="0"
              width="10px"
              color={selectedOption ? 'black' : '#a7a7a7'}
              background="none"
              fontSize={fontSize}
              lineHeight={lineHeight}
              value={value}
              disabled={disabled}
              onFocus={!disabled && this.handleFocus}
              onBlur={this.handleBlur}
              onClick={!disabled && this.handleClick}
              onChange={this.handleChange}
            >
              {options.map(({ value, label }) => (
                <option value={value}>{label}</option>
              ))}
            </Block>
            <DownIcon />
          </Row>
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom="solid 1px #a7a7a7"
          />
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom="solid 2px #EA5A99"
            opacity={focus ? '1' : '0'}
            transition="opacity .15s ease"
          />
        </Block>
        {!errorText && <InputInfo>{infoText}</InputInfo>}
        {errorText && <InputError>{errorText}</InputError>}
      </Block>
    )
  }
}
