import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { parse, stringify } from 'querystring'
import dateFormat from 'dateformat'
import { getName } from '../../languages'

import {
  Row,
  Block,
  Input,
  ProfilePicture,
  Select,
  SpacerVertical,
  SpacerHorizontal,
  Button
} from '@voicemachine/preact-ui'

import { setProjectSearchParams, fetchProjects } from '../../actions'
import { route } from '../../router'

import { Table, TableRow, TableHeader, TableData } from '../Table'

const goToUser = (user) => (e) => {
  e.stopPropagation()
  route(`/users/${user.id}`)
}

class SearchProjects extends Component {
  componentDidMount() {
    const params = parse(location.search.slice(1))
    if (Object.keys(params).length > 0) {
      setTimeout(() => setProjectSearchParams(params, true))
    } else if (this.props.projectSearchParams) {
      this.fetchProjects(this.props.projectSearchParams)
    } else {
      this.fetchProjects({ orderBy: 'lastModified' })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.projectSearchParams &&
      this.props.projectSearchParams !== prevProps.projectSearchParams
    ) {
      this.fetchProjects(this.props.projectSearchParams)
    }
  }

  fetchProjects = (params) => {
    this.updateQueryString(params)
    fetchProjects({ ...params, limit: 1000 })
  }

  updateQueryString = (params) => {
    const urlParams = {}
    Object.entries(params).forEach(([key, value]) => {
      if (value && value.trim() !== '') {
        urlParams[key] = value.trim()
      }
    })
    history.replaceState(
      null,
      null,
      Object.keys(urlParams).length > 0 ? '?' + stringify(urlParams) : ''
    )
  }

  render() {
    const { projectSearchParams = {}, projects = [] } = this.props
    const { query, status, orderBy = 'lastModified' } = projectSearchParams
    const { cap = 30 } = this.state
    return (
      <Block flex="1" overflow="auto">
        <Row>
          <Input
            label="Search projects"
            value={query}
            onChange={(query) => setProjectSearchParams({ query })}
          />
        </Row>
        <Row>
          <Select
            label="Status"
            value={status}
            onChange={(status) => setProjectSearchParams({ status })}
            options={[
              { value: '', label: 'Any' },
              { value: 'assigned', label: 'Assigned' },
              { value: 'not-assigned', label: 'Not assigned' },
              { value: 'accepted', label: 'Accepted' },
              { value: 'not-accepted', label: 'Not accepted' },
              { value: 'approved', label: 'Approved' },
              { value: 'not-approved', label: 'Not approved' }
            ]}
          />
          <Select
            label="Sort order"
            value={orderBy}
            onChange={(orderBy) => setProjectSearchParams({ orderBy })}
            options={[
              { value: 'name', label: 'Name' },
              { value: 'lastModified', label: 'Last modified' },
              { value: 'dueDate', label: 'Due date' }
            ]}
          />
        </Row>
        <SpacerVertical />
        {projects.length > 0 && (
          <Block padding="0 20px">
            <Table>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>PO/ref</TableHeader>
                <TableHeader>Language</TableHeader>
                <TableHeader>Due date</TableHeader>
                <TableHeader>Client</TableHeader>
                <TableHeader>Assigned</TableHeader>
                <TableHeader>Accepted</TableHeader>
                <TableHeader>Approved</TableHeader>
                <TableHeader>Last modified</TableHeader>
              </TableRow>
              {projects.slice(0, cap).map((project) => {
                if (!project.client) {
                  return null
                }
                const voice = project.voices.find((voice) => voice.isAssigned)
                return (
                  <TableRow
                    cursor="pointer"
                    borderBottom="solid 1px #f1f1f1"
                    onClick={() => route(`/projects/${project.id}`)}
                  >
                    <TableData>{project.name}</TableData>
                    <TableData>{project.purchaseOrder}</TableData>
                    <TableData>{getName(project.language)}</TableData>
                    <TableData>{project.dueDate || '-'}</TableData>
                    <TableData onClick={goToUser(project.client)}>
                      {project.client.name}
                      <Block fontSize="12px">
                        <a
                          href={`mailto:${project.client.email}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {project.client.email}
                        </a>
                      </Block>
                    </TableData>
                    {voice && (
                      <TableData onClick={goToUser(voice)}>
                        <Row>
                          {voice.picture && [
                            <ProfilePicture size="32" src={voice.picture} />,
                            <SpacerHorizontal />
                          ]}
                          <Block>
                            {voice.name}
                            <Block fontSize="12px">
                              <a
                                href={`mailto:${voice.email}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {voice.email}
                              </a>
                            </Block>
                          </Block>
                        </Row>
                      </TableData>
                    )}
                    {!voice && <TableData>-</TableData>}
                    <TableData>{project.isAccepted ? 'Yes' : 'No'}</TableData>
                    <TableData>{project.isApproved ? 'Yes' : 'No'}</TableData>
                    <TableData whiteSpace="nowrap">
                      {dateFormat(project.lastModified, 'yyyy-mm-dd HH:MM')}
                    </TableData>
                  </TableRow>
                )
              })}
            </Table>
            {projects.length > cap && (
              <Block>
                <SpacerVertical />
                <Row justifyContent="center">
                  <Button onClick={() => this.setState({ cap: cap + 30 })}>Show more</Button>
                </Row>
              </Block>
            )}
            <SpacerVertical large />
          </Block>
        )}
      </Block>
    )
  }
}

export default connect('projectSearchParams, projects')(SearchProjects)
