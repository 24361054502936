import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { parse, stringify } from 'querystring'
import dateFormat from 'dateformat'

import {
  Row,
  Block,
  Input,
  Select,
  ProfilePicture,
  SpacerVertical,
  SpacerHorizontal,
  Button
} from '@voicemachine/preact-ui'

import { setUserSearchParams, fetchUsers, fetchLabels } from '../../actions'
import { route } from '../../router'

import { Table, TableRow, TableHeader, TableData } from '../Table'

class SearchUsers extends Component {
  componentWillMount() {
    const params = parse(location.search.slice(1))
    if (Object.keys(params).length > 0) {
      setTimeout(() => setUserSearchParams(params, true))
    } else if (this.props.userSearchParams) {
      this.fetchUsers(this.props.userSearchParams)
    } else {
      this.fetchUsers({})
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userSearchParams && this.props.userSearchParams !== prevProps.userSearchParams) {
      this.fetchUsers(this.props.userSearchParams)
    }
  }

  fetchUsers = (params) => {
    this.updateQueryString(params)
    fetchUsers(params)
    fetchLabels()
  }

  updateQueryString = (params) => {
    const urlParams = {}
    Object.entries(params).forEach(([key, value]) => {
      if (value && value.trim() !== '') {
        urlParams[key] = value.trim()
      }
    })
    history.replaceState(
      null,
      null,
      Object.keys(urlParams).length > 0 ? '?' + stringify(urlParams) : ''
    )
  }

  render() {
    const { userSearchParams = {}, users = [], labels = [] } = this.props
    const { query, label, gender, status, voiceStatus, orderBy = 'name' } = userSearchParams
    const { cap = 30 } = this.state

    return (
      <Block flex="1" overflow="auto">
        <Row>
          <Input
            label="Search users"
            value={query}
            onChange={(query) => setUserSearchParams({ query })}
          />
        </Row>
        <Row>
          <Select
            label="Gender"
            value={gender}
            onChange={(gender) => setUserSearchParams({ gender })}
            options={[
              { value: '', label: 'Any' },
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
              { value: 'unknown', label: 'Unknown' }
            ]}
          />
          <Select
            label="User status"
            value={status}
            onChange={(status) => setUserSearchParams({ status })}
            options={[
              { value: '', label: 'Any' },
              { value: 'created', label: 'Created' },
              { value: 'verified', label: 'Verified' }
            ]}
          />
          <Select
            label="Voice status"
            value={voiceStatus}
            onChange={(voiceStatus) => setUserSearchParams({ voiceStatus })}
            options={[
              { value: '', label: 'Any' },
              { value: 'pending', label: 'Pending' },
              { value: 'published', label: 'Published' },
              { value: 'unpublished', label: 'Unpublished' },
              { value: 'declined', label: 'Declined' }
            ]}
          />
          <Select
            label="Label"
            value={label}
            onChange={(label) => setUserSearchParams({ label })}
            options={[
              { value: '', label: 'Any' },
              ...labels.map((label) => {
                return { value: label, label }
              })
            ]}
          />
          <Select
            label="Sort order"
            value={orderBy}
            onChange={(orderBy) => setUserSearchParams({ orderBy })}
            options={[
              { value: 'name', label: 'Name' },
              { value: 'lastModified', label: 'Last modified' }
            ]}
          />
        </Row>
        <SpacerVertical />
        {users.length > 0 && (
          <Block padding="20px">
            <Button
              small
              onClick={() => (location.href = '/api/v1/email-list?' + stringify(userSearchParams))}
            >
              Export email list
            </Button>
            <SpacerVertical />
            <Table>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Company</TableHeader>
                <TableHeader>Gender</TableHeader>
                <TableHeader>User status</TableHeader>
                <TableHeader>Voice status</TableHeader>
                <TableHeader>Registered</TableHeader>
                <TableHeader>Last modified</TableHeader>
              </TableRow>
              {users.slice(0, cap).map((user) => {
                return (
                  <TableRow
                    cursor="pointer"
                    borderBottom="solid 1px #f1f1f1"
                    onClick={() => route(`/users/${user.id}`)}
                  >
                    <TableData>
                      <Row>
                        {user.picture && <ProfilePicture size="32" src={user.picture} />}
                        {!user.picture && <Block width="32px"></Block>}
                        <SpacerHorizontal />
                        <Block>
                          <Block>{user.name}</Block>
                          <Block fontSize="12px">
                            <a href={`mailto:${user.email}`} onClick={(e) => e.stopPropagation()}>
                              {user.email}
                            </a>
                          </Block>
                          {user.labels.length > 0 && (
                            <Block>
                              <SpacerVertical tiny />
                              <Row fontSize="10px" gap="2px" flexWrap="wrap">
                                {user.labels.map((l) => {
                                  const isActive = l === label
                                  return (
                                    <Block
                                      border={isActive ? '' : 'solid 1px #ccc'}
                                      padding="0 5px"
                                      borderRadius="10px"
                                      whiteSpace="nowrap"
                                      background={isActive ? '#ea5a99' : ''}
                                      color={isActive ? '#fff' : '#000'}
                                      props={{
                                        onClick: (e) => {
                                          e.stopPropagation()
                                          if (isActive) {
                                            setUserSearchParams({ label: '' })
                                          } else {
                                            setUserSearchParams({ label: l })
                                          }
                                        }
                                      }}
                                    >
                                      {l}
                                    </Block>
                                  )
                                })}
                              </Row>
                            </Block>
                          )}
                        </Block>
                      </Row>
                    </TableData>
                    <TableData>{user.companyName || '–'}</TableData>
                    <TableData textTransform="capitalize">{user.gender || '-'}</TableData>
                    <TableData textTransform="capitalize">
                      {user.isEmailVerified ? 'Verified' : 'Created'}
                    </TableData>
                    <TableData textTransform="capitalize">
                      {user.isVoice ? user.voiceStatus : '-'}
                    </TableData>
                    <TableData whiteSpace="nowrap">
                      {dateFormat(user.created, 'yyyy-mm-dd HH:MM')}
                    </TableData>
                    <TableData whiteSpace="nowrap">
                      {dateFormat(user.lastModified, 'yyyy-mm-dd HH:MM')}
                    </TableData>
                  </TableRow>
                )
              })}
            </Table>
            {users.length > cap && (
              <Block>
                <SpacerVertical />
                <Row alignItems="center" justifyContent="center">
                  <Button onClick={() => this.setState({ cap: cap + 30 })}>Show more</Button>
                </Row>
              </Block>
            )}
          </Block>
        )}
      </Block>
    )
  }
}

export default connect('userSearchParams, users, labels')(SearchUsers)
