import { h } from 'preact'

import {
  MusicNoteIcon,
  OndemandVideoIcon,
  ImageIcon,
  AttachmentIcon
} from '@voicemachine/preact-ui'

const FileIcon = ({ mime, ...props }) => {
  if (!mime) {
    return <AttachmentIcon {...props} />
  }
  if (mime.startsWith('image/')) {
    return <ImageIcon {...props} />
  }
  if (mime.startsWith('audio/')) {
    return <MusicNoteIcon {...props} />
  }
  if (mime.startsWith('video/')) {
    return <OndemandVideoIcon {...props} />
  }
  return <AttachmentIcon {...props} />
}

export default FileIcon
