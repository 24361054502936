import { h, Component } from 'preact'
import { Switch } from '../../router'

import CreateProjectTemplate from './CreateProjectTemplate'
import EditProjectTemplate from './EditProjectTemplate'
import ListProjectTemplates from './ListProjectTemplates'

class ProjectTemplates extends Component {
  render() {
    return (
      <Switch>
        <CreateProjectTemplate path="/project-templates/create" />
        <EditProjectTemplate path="/project-templates/:templateId" />
        <ListProjectTemplates />
      </Switch>
    )
  }
}

export default ProjectTemplates
