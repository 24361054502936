import { h } from 'preact'
import { Block, ProfilePicture, Row } from '@voicemachine/preact-ui'

const Avatar = ({ user, size = 32 }) => {
  if (user.picture) {
    return (
      <Block props={{ title: user.name }}>
        <ProfilePicture name={user.name} src={user.picture} size={size} />
      </Block>
    )
  }
  return (
    <Row
      alignItems="center"
      justifyContent="center"
      width={`${size}px`}
      height={`${size}px`}
      background="#eee"
      color="#888"
      borderRadius="50%"
      props={{ title: user.name }}
    >
      {user.name.charAt(0).toUpperCase()}
    </Row>
  )
}

export default Avatar
