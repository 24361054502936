import { h, Component } from 'preact'
import { Block, InlineBlock } from './blocks'
import { Loading } from './Loading'

const getFontSize = ({ small, tiny }) => {
  if (small) {
    return 12
  }
  if (tiny) {
    return 10
  }
  return 14
}

const getPadding = ({ small, tiny }) => {
  if (small) {
    return '0 10px'
  }
  if (tiny) {
    return '0 5px'
  }
  return '0 15px'
}

const getBackground = ({ white, focus }) => {
  if (focus) {
    if (white) {
      return 'rgba(0, 0, 0, 0.05)'
    }
    return 'rgba(234, 90, 153, 0.075)'
  }
  return 'none'
}

const getColor = ({ white, disabled }) => {
  if (disabled) {
    if (white) {
      return 'rgba(255, 255, 255, 0.25)'
    }
    return 'rgba(0, 0, 0, 0.25)'
  }
  if (white) {
    return 'white'
  }
  return '#1f1f1f'
}

const getFill = ({ white, disabled }) => {
  if (disabled) {
    if (white) {
      return 'rgba(255, 255, 255, 0.20)'
    }
    return 'rgba(0, 0, 0, 0.20)'
  }
  if (white) {
    return '#fafafa'
  }
  return '#444'
}

export class ButtonTertiary extends Component {
  state = {
    focus: false
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  render() {
    const { focus } = this.state
    const {
      children,
      disabled,
      loading,
      onClick,
      icon,
      primary,
      small,
      white,
      tiny,
      ...props
    } = this.props

    const clickable = !disabled && !loading && onClick
    const webkitTapHighlightColor = !clickable ? 'rgba(0, 0, 0, 0)' : ''
    const fontSize = getFontSize({ small, tiny })
    const color = getColor({ white, disabled })
    const fill = getFill({ white, disabled })

    return (
      <Block
        component="button"
        overflow="hidden"
        textOverflow="ellipsis"
        onClick={onClick}
        fontSize={fontSize + 'px'}
        fontWeight={primary ? 'bold' : 'normal'}
        lineHeight={fontSize + 2 + 'px'}
        textTransform="uppercase"
        letterSpacing="0.5px"
        background={getBackground({ white, focus })}
        color={color}
        padding={getPadding({ small, tiny })}
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        outline="none"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        webkitTapHighlightColor={webkitTapHighlightColor}
        {...props}
      >
        {icon && (
          <InlineBlock marginRight={!loading && children.length > 0 ? '5px' : null} fill={fill}>
            {icon}
          </InlineBlock>
        )}
        {loading && <Loading stroke={fill} />}
        {!loading && children}
      </Block>
    )
  }
}
