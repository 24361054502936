import { h, Component } from 'preact'
import Path from 'path-parser'
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory()

export const route = (path, replace) => {
  if (replace) {
    history.replace(path)
  } else {
    history.push(path)
  }
}

export const goBack = (fallback = '/') => {
  if (history.action === 'PUSH' || history.action === 'REPLACE') {
    history.goBack()
  } else {
    route(fallback, true)
  }
}

const matchPath = (path, exact = false) => {
  const p = new Path(path)
  if (exact) {
    return p.test(history.location.pathname)
  } else {
    return p.partialTest(history.location.pathname)
  }
}

export class Router extends Component {
  componentWillMount() {
    this.unlisten = history.listen(() => this.forceUpdate())
  }

  componentWillUmnount() {
    this.unlisten()
  }

  render() {
    return <Switch>{this.props.children}</Switch>
  }
}

export const Switch = ({ children }) => {
  for (let child of children) {
    const { attributes = {} } = child
    const { path, exact } = attributes
    if (!path) {
      return child
    }
    const match = matchPath(path, exact)
    if (match) {
      return h(child.nodeName, { ...child.attributes, ...match }, ...child.children)
    }
  }
  return null
}

export class Redirect extends Component {
  componentDidMount() {
    route(this.props.to, true)
  }

  render() {
    return null
  }
}
