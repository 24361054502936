import { h } from 'preact'

import { Block } from './blocks'
import { NotificationBall } from './NotificationBall'

export const ProfilePicture = ({
  src,
  name,
  className,
  notification = false,
  disabled = false,
  onClick,
  size,
  ...styleProps
}) => {
  const imgStyle = {
    display: 'block',
    borderRadius: '50%',
    border: 'none',
    width: size + 'px',
    height: size + 'px',
    filter: disabled ? 'grayscale(1) opacity(0.3)' : '',
    ...styleProps
  }

  return (
    <Block position="relative">
      <img
        className="avatar"
        alt={`Profile picture of ${name}`}
        crossorigin="anonymous"
        src={src}
        className={className}
        style={imgStyle}
        onClick={disabled ? null : onClick}
      />
      {notification && <NotificationBall />}
    </Block>
  )
}
