import { h, Component } from 'preact'
import { Block } from './blocks'

export class Ripple extends Component {
  state = {
    animating: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animating: true })
      setTimeout(() => {
        this.props.onDone()
      }, this.props.time || 400)
    }, 0)
  }

  render() {
    const { x, y, width, height, color, time = 400 } = this.props
    const size = Math.max(width, height)
    const { animating } = this.state
    return (
      <Block
        position="absolute"
        top={`${y}px`}
        left={`${x}px`}
        width="2px"
        height="2px"
        borderRadius="50%"
        backgroundColor={color ? color : 'rgba(234, 90, 153, 0.5)'}
        opacity={animating ? '0.25' : '1'}
        transform={animating ? `scale(${size})` : 'scale(1)'}
        transition={`opacity ${time}ms ease-in-out, transform ${time}ms ease-in-out`}
      ></Block>
    )
  }
}
