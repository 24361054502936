import { h, Component } from 'preact'
import { Inline } from './blocks'

const getFontSize = ({ small, tiny }) => {
  if (tiny) {
    return '12px'
  }
  if (small) {
    return '14px'
  }
  return null
}

class RegularLink extends Component {
  state = {
    focus: false
  }
  handleFocus = () => this.setState({ focus: true })
  handleBlur = () => this.setState({ focus: false })
  render() {
    const { focus } = this.state
    const { href, target, children, white, small, tiny, disabled = false } = this.props
    const fontSize = getFontSize({ small, tiny })
    return (
      <Inline
        component="a"
        target={target}
        href={href}
        outline="none"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        textDecoration={focus ? 'underline' : 'none'}
        color={white ? 'white' : '#EA5A99'}
        opacity={disabled ? 0.33 : 1}
        fontSize={fontSize}
        cursor={!disabled ? 'pointer' : 'default'}
      >
        {children}
      </Inline>
    )
  }
}

class InternalLink extends Component {
  state = {
    focus: false
  }
  handleFocus = () => this.setState({ focus: true })
  handleBlur = () => this.setState({ focus: false })
  handleKeyPress = (e) => {
    const { onClick, disabled } = this.props
    if (!disabled && onClick) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        onClick()
      }
    }
  }
  render() {
    const { focus } = this.state
    const { children, white, small, tiny, disabled = false, onClick } = this.props
    const fontSize = getFontSize({ small, tiny })
    return (
      <Inline
        role="link"
        outline="none"
        tabIndex="0"
        onClick={!disabled ? onClick : undefined}
        onKeyPress={this.handleKeyPress}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        textDecoration={focus ? 'underline' : 'none'}
        color={white ? 'white' : '#EA5A99'}
        opacity={disabled ? 0.33 : 1}
        fontSize={fontSize}
        cursor={!disabled ? 'pointer' : 'default'}
      >
        {children}
      </Inline>
    )
  }
}

export const Link = ({ children, ...props }) => {
  const { href } = props
  if (href) {
    return <RegularLink {...props}>{children}</RegularLink>
  } else {
    return <InternalLink {...props}>{children}</InternalLink>
  }
}
