import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Button, Input, SpacerVertical } from '@voicemachine/preact-ui'

import { Router, Switch, Redirect, route } from '../router'

import AppMenu from './AppMenu'
import Dashboard from './Dashboard'
import Users from './Users'
import Projects from './Projects'
import ApiKeys from './ApiKeys'
import ProjectTemplates from './ProjectTemplates'

import Player from './Player'
import { store } from '../store'
import { verifyLoggedIn } from '../actions'
import { post } from '../api'
import ManualInvoices from './ManualInvoices'
import ManualInvoice from './ManualInvoice'

class Error extends Component {
  close() {
    store.setState({ error: null })
  }

  render({ error }) {
    console.log('Error!', error)

    return (
      <Block
        class="Error"
        position="fixed"
        top="0"
        left="0"
        bottom="0"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        background="rgba(0, 0, 0, 0.3)"
        props={{
          onClick: this.close
        }}
      >
        <Block background="white" padding="20px">
          <h1>Error:</h1>
          {error.message}
          <br />
          <br />
          <Button small onClick={this.close}>
            Ok
          </Button>
        </Block>
      </Block>
    )
  }
}

class Login extends Component {
  state = {
    email: '',
    emailSent: false,
    isSendingEmail: false
  }

  handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    const { email } = this.state
    if (email) {
      this.setState({ isSendingEmail: true })
      await post(`/magic-admin-link`, { email })
      this.setState({ emailSent: true, isSendingEmail: false })
    }
  }

  render() {
    const { email, emailSent, isSendingEmail } = this.state

    return (
      <Block margin="40px auto" maxWidth="500px">
        {emailSent && (
          <Block margin="0 30px">
            Email sent to <code>{email}</code>
          </Block>
        )}
        {!emailSent && (
          <form onSubmit={this.handleSubmit}>
            <Input
              label="Email"
              type="email"
              value={email}
              onChange={(email) => this.setState({ email })}
            />
            <SpacerVertical small />
            <Button type="submit" margin="0 30px" loading={isSendingEmail}>
              Login
            </Button>
          </form>
        )}
      </Block>
    )
  }
}

class App extends Component {
  async componentWillMount() {
    const params = new URLSearchParams(location.search)
    if (params.has('token')) {
      try {
        const { data: email } = await post('/consume-magic-link', { token: params.get('token') })
        store.setState({ email })
        route('/', true)
      } catch (error) {
        store.setState({ error })
      }
    } else {
      verifyLoggedIn()
    }
  }

  render({ isVerifyingLoggedIn = true, email, error }) {
    if (!isVerifyingLoggedIn && !email) {
      return <Login />
    }

    return (
      <Router>
        <Block>
          <AppMenu />
          {email && (
            <Block>
              <Switch>
                <Dashboard path="/dashboard" />
                <Users path="/users" />
                <Projects path="/projects" />
                <ProjectTemplates path="/project-templates" />
                <ManualInvoice path="/manual-invoices/:invoiceId" />
                <ManualInvoices path="/manual-invoices" />
                <ApiKeys path="/api-keys" />
                <Redirect to="/dashboard" />
              </Switch>
              <Player />
            </Block>
          )}
          {error && <Error error={error} />}
        </Block>
      </Router>
    )
  }
}

export default connect('isVerifyingLoggedIn, email, error')(App)
