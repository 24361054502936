import { h, Component } from 'preact'
import { Block } from './blocks'

export const Label = ({ large, error, children }) => (
  <Block
    fontSize={large ? '15px' : '12px'}
    lineHeight={large ? '24px' : '18px'}
    color={error ? 'red' : '#a7a7a7'}
  >
    {children}
  </Block>
)
