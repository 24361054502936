import { h } from 'preact'
import { Row, ProfilePicture, Block } from '@voicemachine/preact-ui'

const UserInfo = ({ user, gray, onClick }) => {
  return (
    <Row padding="10px" cursor={onClick ? 'pointer' : ''} onClick={onClick} alignItems="center">
      {user.picture && <ProfilePicture size="32" src={user.picture} disabled={gray} />}
      <Block marginLeft={user.picture ? '10px' : 0}>
        <Block>{user.name}</Block>
        <Block fontSize="12px" color="#666">
          {user.email}
        </Block>
      </Block>
    </Row>
  )
}

export default UserInfo
