import { h, Component } from 'preact'

import { Block, Row } from './blocks'
import { DownIcon } from './icons'
import { Label } from './Label'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'

export class SimpleSelect extends Component {
  state = {
    focus: false
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = (e) => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick()
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleClick()
    }
  }

  render() {
    const {
      label,
      placeholder,
      value,
      infoText,
      errorText,
      maxWidth,
      disabled,
      large = false
    } = this.props
    const { focus } = this.state

    const fontSize = large ? '20px' : '16px'
    const lineHeight = large ? '24px' : '18px'

    return (
      <Block
        width="calc(100% - 60px)"
        maxWidth={maxWidth ? maxWidth : 'none'}
        margin="0 30px"
        boxSizing="border-box"
      >
        <Block component="label" position="relative">
          {label && (
            <Label large={large} error={errorText}>
              {label}
            </Label>
          )}
          <Row alignItems="center" height={large ? '40px' : '35px'}>
            <Block
              role="select"
              tabIndex={!disabled ? '0' : undefined}
              outline="none"
              onFocus={!disabled && this.handleFocus}
              onBlur={!disabled && this.handleBlur}
              onKeyDown={this.handleKeyDown}
              onClick={this.handleClick}
              flex="1"
              padding="0"
              width="10px"
              color={value ? 'black' : '#a7a7a7'}
              background="none"
              fontSize={fontSize}
              lineHeight={lineHeight}
              fontStyle={disabled ? 'italic' : undefined}
              disabled={disabled}
            >
              {value || placeholder}
            </Block>
            <DownIcon />
          </Row>
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom="solid 1px #a7a7a7"
          />
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom="solid 2px #EA5A99"
            opacity={focus ? '1' : '0'}
            transition="opacity .15s ease"
          />
        </Block>
        {!errorText && <InputInfo>{infoText}</InputInfo>}
        {errorText && <InputError>{errorText}</InputError>}
      </Block>
    )
  }
}
