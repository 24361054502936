import { h, Component } from 'preact'
import { connect } from 'unistore/preact'

import { Switch } from '../../router'
import { fetchApiKeys } from '../../actions'

import CreateApiKey from './CreateApiKey'
import EditApiKey from './EditApiKey'
import ListApiKeys from './ListApiKeys'

class ApiKeys extends Component {
  componentDidMount() {
    fetchApiKeys()
  }
  render() {
    return (
      <Switch>
        <CreateApiKey path="/api-keys/add" />
        <EditApiKey path="/api-keys/:value" />
        <ListApiKeys />
      </Switch>
    )
  }
}

export default connect('isFetchingApiKeys,apiKeys')(ApiKeys)
