import { h } from 'preact'

import { Block } from './blocks'
import { Link } from './Link'

const fixUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return 'http://' + url
  }
  return url
}

export const Output = ({ label, value, url, placeholder, large = false, onClick }) => {
  const height = large ? '75px' : '60px'
  const top = large ? '44px' : '33px'
  const fontSize = large ? '20px' : '16px'
  const lineHeight = large ? '24px' : '18px'
  const labelFontSize = large ? '15px' : '12px'
  const labelLineHeight = large ? '24px' : '18px'
  const labelTop = large ? '12px' : '10px'
  const str = value ? value : placeholder
  const color = value ? undefined : '#a9a9a9'
  return (
    <Block height={height} position="relative" padding="0 30px" onClick={onClick}>
      <Block
        position="absolute"
        top={labelTop}
        fontSize={labelFontSize}
        lineHeight={labelLineHeight}
        color="#a7a7a7"
      >
        {label}
      </Block>
      <Block
        position="absolute"
        top={top}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
      >
        {url && (
          <Link href={fixUrl(url)} target="_blank">
            {str}
          </Link>
        )}
        {!url && str}
      </Block>
    </Block>
  )
}
