import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Row,
  Block,
  Input,
  Button,
  SpacerVertical,
  SpacerHorizontal
} from '@voicemachine/preact-ui'

import { updateVoiceDemo, deleteVoiceDemo } from '../../../actions'
import { goBack } from '../../../router'

class EditVoiceDemo extends Component {
  state = {}

  componentWillMount() {
    const voiceDemo = this.props.user.voiceDemos.find(
      (voiceDemo) => voiceDemo.id === this.props.voiceDemoId
    )
    this.setState({
      language: voiceDemo.language,
      dialect: voiceDemo.dialect,
      types: voiceDemo.types ? voiceDemo.types.join(', ') : '',
      tones: voiceDemo.tones ? voiceDemo.tones.join(', ') : '',
      age: voiceDemo.age
    })
  }

  save = async () => {
    if (
      await updateVoiceDemo(this.props.voiceDemoId, {
        ...this.state,
        types: this.state.types.split(',').map((str) => str.trim()),
        tones: this.state.tones.split(',').map((str) => str.trim())
      })
    ) {
      this.back()
    }
  }

  delete = async () => {
    if (await deleteVoiceDemo(this.props.voiceDemoId)) {
      this.back()
    }
  }

  back = () => goBack(`/users/${this.props.userId}/voice-demos`)

  render() {
    const { user, isUpdatingVoiceDemo, isDeletingVoiceDemo } = this.props
    const voiceDemo = user.voiceDemos.find((voiceDemo) => voiceDemo.id === this.props.voiceDemoId)

    if (!voiceDemo) {
      return null
    }

    const { language, dialect, types, tones, age } = this.state

    const isOk = types.trim() !== ''

    return (
      <Block flex="1" height="100%" background="#FAFAFA" overflow="auto">
        <SpacerVertical />
        <Input
          label="Language"
          value={language}
          onChange={(language) => this.setState({ language })}
        />
        <SpacerVertical />
        <Input label="Dialect" value={dialect} onChange={(dialect) => this.setState({ dialect })} />
        <SpacerVertical />
        <Input label="Type" value={types} onChange={(types) => this.setState({ types })} />
        <SpacerVertical />
        <Input label="Tones" value={tones} onChange={(tones) => this.setState({ tones })} />
        <SpacerVertical />
        <Input label="Age" value={age} onChange={(age) => this.setState({ age })} />
        <SpacerVertical />
        <Row justifyContent="center">
          <Button onClick={this.back}>Cancel</Button>
          <SpacerHorizontal />
          <Button
            background="red"
            color="white"
            loading={isDeletingVoiceDemo}
            onClick={this.delete}
          >
            Delete
          </Button>
          <SpacerHorizontal />
          <Button primary loading={isUpdatingVoiceDemo} disabled={!isOk} onClick={this.save}>
            Save
          </Button>
        </Row>
        <SpacerVertical />
      </Block>
    )
  }
}

export default connect('user, isUpdatingVoiceDemo, isDeletingVoiceDemo')(EditVoiceDemo)
