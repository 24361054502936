import { h } from 'preact'
import { Block, Inline } from './blocks'

export const SeparatorHorizontal = ({ large }) => {
  const width = large ? '3px' : '1px'
  return <Inline height="100%" width={width} backgroundColor="#eee" />
}

export const SeparatorVertical = ({ large, margin = 0 }) => {
  const height = large ? '3px' : '1px'
  const marginCss = `0 ${margin}px`
  const width = margin > 0 ? `calc(100% - ${margin * 2}px)` : '100%'
  return <Block width={width} height={height} backgroundColor="#eee" margin={marginCss} />
}
