import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Block,
  Button,
  Input,
  Row,
  SpacerHorizontal,
  SpacerVertical,
  ToggleSwitch
} from '@voicemachine/preact-ui'

import { updateApiKey } from '../../actions'

import { allScopes } from './CreateApiKey'

class EditApiKey extends Component {
  componentDidMount() {
    this.populateState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiKeys !== this.props.apiKeys) {
      this.populateState()
    }
  }

  populateState = () => {
    const { value, apiKeys = [] } = this.props
    const apiKey = apiKeys.find((key) => key.value === value)
    if (apiKey) {
      this.setState({ comment: apiKey.comment, scopes: apiKey.scopes })
    }
  }

  toggleScope = (scope) => {
    const { scopes = [] } = this.state
    if (scopes.includes(scope)) {
      this.setState({ scopes: scopes.filter((s) => s !== scope) })
    } else {
      this.setState({ scopes: [...scopes, scope] })
    }
  }

  save = async () => {
    if (await updateApiKey(this.props.value, this.state)) {
      history.back()
    }
  }

  render() {
    const { comment = '', scopes = [] } = this.state
    return (
      <Block padding="10px 0">
        <Input label="Comment" value={comment} onChange={(comment) => this.setState({ comment })} />
        <SpacerVertical />
        {allScopes.map((group) => {
          return (
            <Block>
              {group.map((scope) => {
                return (
                  <ToggleSwitch
                    label={scope}
                    on={scopes.includes(scope)}
                    onChange={() => this.toggleScope(scope)}
                  />
                )
              })}
              <SpacerVertical />
            </Block>
          )
        })}
        <Row padding="10px">
          <Button onClick={this.save}>Save</Button>
          <SpacerHorizontal />
          <Button onClick={() => history.back()}>Cancel</Button>
        </Row>
      </Block>
    )
  }
}

export default connect('apiKeys, isUpdatingApiKey')(EditApiKey)
