import { h } from 'preact'
import { connect } from 'unistore/preact'

import { Col, Block } from '@voicemachine/preact-ui'
import { route } from '../../../router'

const MenuItem = ({ path, label }) => {
  const isActive = window.location.pathname.startsWith(path)
  const handleClick = () => route(path)
  return (
    <Block
      padding="10px 20px"
      fontWeight={isActive ? 'bold' : ''}
      cursor={isActive ? '' : 'pointer'}
      onClick={isActive ? null : handleClick}
    >
      {label}
    </Block>
  )
}

const ProjectMenu = ({ project }) => {
  const items = [
    { path: `/projects/${project.id}/details`, label: 'Project' },
    { path: `/projects/${project.id}/voices`, label: 'Voices' },
    { path: `/projects/${project.id}/files`, label: 'Files' },
    { path: `/projects/${project.id}/chat`, label: 'Chat' },
  ]

  return (
    <Col padding="10px" width="200px">
      {items.map((item) => (
        <MenuItem {...item} />
      ))}
    </Col>
  )
}

export default connect('project')(ProjectMenu)
