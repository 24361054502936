import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, SpacerVertical, ButtonTertiary, DeleteIcon } from '@voicemachine/preact-ui'

import { setState } from '../../../actions'

import Missing from '../../Missing'

class StudioDemo extends Component {
  play = (studioQualityDemo) => {
    setState({ player: { uri: studioQualityDemo.processedUri } })
  }

  render() {
    const { user } = this.props

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        {!user.studioQualityDemo && <Missing>No studio demo</Missing>}
        <Row padding="0 30px" height="60px">
          <Block flex="1" onClick={() => this.play(user.studioQualityDemo)}>
            Studio Quality Demo
          </Block>
        </Row>
      </Block>
    )
  }
}

export default connect('user')(StudioDemo)
