import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Button, Row, SpacerHorizontal, SpacerVertical } from '@voicemachine/preact-ui'
import dateFormat from 'dateformat'

import { deleteApiKey } from '../../actions'

import { Table, TableData, TableHeader, TableRow } from '../Table'
import { route } from '../../router'

class ListApiKeys extends Component {
  delete = (value) => {
    if (confirm('Are you sure?')) {
      deleteApiKey(value)
    }
  }
  toggleVisible = (value) => {
    const { visible = [] } = this.state
    if (visible.includes(value)) {
      this.setState({ visible: visible.filter((v) => v !== value) })
    } else {
      this.setState({ visible: [...visible, value] })
    }
  }
  render() {
    const { isFetchingApiKeys, apiKeys = [] } = this.props
    const { visible = [] } = this.state
    return (
      <Block padding="10px">
        <Table>
          <TableRow>
            <TableHeader>Comment</TableHeader>
            <TableHeader>Value</TableHeader>
            <TableHeader>Scope</TableHeader>
            <TableHeader>Created</TableHeader>
            <TableHeader>Accessed</TableHeader>
            <TableHeader>Edit</TableHeader>
            <TableHeader>Delete</TableHeader>
          </TableRow>
          {apiKeys.map((apiKey) => {
            const isVisible = visible.includes(apiKey.value)
            return (
              <TableRow>
                <TableData verticalAlign="top">{apiKey.comment}</TableData>
                <TableData verticalAlign="top" fontFamily="monospace">
                  <Row color={isVisible ? '#000' : '#999'}>
                    <Block>
                      {isVisible
                        ? apiKey.value
                        : apiKey.value.slice(0, 10) + '......................................'}
                    </Block>
                    <SpacerHorizontal />
                    <Block cursor="pointer" onClick={() => this.toggleVisible(apiKey.value)}>
                      {isVisible && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path
                            d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
                            fill="none"
                          />
                          <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
                        </svg>
                      )}
                      {!isVisible && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                        </svg>
                      )}
                    </Block>
                  </Row>
                </TableData>
                <TableData>
                  {apiKey.scopes.sort().map((scope) => {
                    return <Block whiteSpace="nowrap">{scope}</Block>
                  })}
                </TableData>
                <TableData verticalAlign="top">
                  {dateFormat(apiKey.created, 'yyyy-mm-dd')}
                </TableData>
                <TableData verticalAlign="top">
                  {apiKey.accessed ? dateFormat(apiKey.accessed, 'yyyy-mm-dd HH:MM') : '-'}
                </TableData>
                <TableData verticalAlign="top">
                  <Block cursor="pointer" onClick={() => route(`/api-keys/${apiKey.value}`)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                    </svg>
                  </Block>
                </TableData>
                <TableData verticalAlign="top">
                  <Block cursor="pointer" onClick={() => this.delete(apiKey.value)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                    </svg>
                  </Block>
                </TableData>
              </TableRow>
            )
          })}
        </Table>
        <SpacerVertical />
        <Button onClick={() => route('/api-keys/add')}>+ New key</Button>
      </Block>
    )
  }
}

export default connect('isFetchingApiKeys,apiKeys')(ListApiKeys)
