import { h, Component, Fragment } from 'preact'
import { connect } from 'unistore/preact'
import {
  Row,
  Block,
  Input,
  Select,
  Button,
  SpacerVertical,
  TextArea,
  Section,
  InlineBlock,
  CloseIcon,
  Loading
} from '@voicemachine/preact-ui'
import { getCodeList } from 'country-list'

import { getMagicLink, updateUser } from '../../../actions'
import { route } from '../../../router'
import { get } from '../../../api'

const countryList = getCodeList()

const getCountryOptions = () => {
  const countries = Object.entries(countryList).map(([code, name]) => ({
    value: code,
    label: name
  }))
  return [
    {
      value: '',
      label: 'Not specified'
    },
    ...countries
  ]
}

const yesNoOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
]

class EditUser extends Component {
  state = {
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    gender: this.props.user.gender,
    companyName: this.props.user.companyName,
    phone: this.props.user.phone || '',
    notes: this.props.user.notes || '',
    companyAddressLine1: this.props.user.companyAddressLine1 || '',
    companyAddressLine2: this.props.user.companyAddressLine2 || '',
    companyPostalCode: this.props.user.companyPostalCode || '',
    companyCity: this.props.user.companyCity || '',
    companyCountry: this.props.user.companyCountry || '',
    companyHomepage: this.props.user.companyHomepage || '',
    companyVatId: this.props.user.companyVatId || '',
    companyTaxId: this.props.user.companyTaxId || '',
    microphone: this.props.user.microphone || '',
    soundCard: this.props.user.soundCard || '',
    recordingSoftware: this.props.user.recordingSoftware || '',
    recordingSpace: this.props.user.recordingSpace || '',
    isEmailVerified: this.props.user.isEmailVerified ? 'yes' : 'no',
    isVoice: this.props.user.isVoice ? 'yes' : 'no',
    isApprovedVoice: this.props.user.isApprovedVoice ? 'yes' : 'no',
    voiceStatus: this.props.user.voiceStatus,
    hasStripePayout: this.props.user.hasStripePayout ? 'yes' : 'no',
    internationalBankTransferInfo: this.props.user.internationalBankTransferInfo || '',
    invoiceEmail: this.props.user.invoiceEmail || '',
    hasReverseVatReporting: this.props.user.hasReverseVatReporting ? 'yes' : 'no',
    hidePayouts: this.props.user.hidePayouts ? 'yes' : 'no',
    manualInvoice: this.props.user.manualInvoice ? 'yes' : 'no',
    allowedProjects: this.props.user.allowedProjects || [],
    selectedProject: null,
    labels: this.props.user.labels || [],
    stripeAccountId: this.props.user.stripe ? this.props.user.stripe.accountId : '',
    fetchingHubspotLink: true
  }

  componentDidMount() {
    get(`/users/${this.props.user.id}/hubspot-link`)
      .then((res) => {
        if (res.data) {
          this.setState({ hubspotLink: res.data })
        }
      })
      .finally(() => this.setState({ fetchingHubspotLink: false }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        email: this.props.user.email,
        gender: this.props.user.gender,
        companyName: this.props.user.companyName,
        phone: this.props.user.phone || '',
        notes: this.props.user.notes || '',
        companyAddressLine1: this.props.user.companyAddressLine1 || '',
        companyAddressLine2: this.props.user.companyAddressLine2 || '',
        companyPostalCode: this.props.user.companyPostalCode || '',
        companyCity: this.props.user.companyCity || '',
        companyCountry: this.props.user.companyCountry || '',
        companyHomepage: this.props.user.companyHomepage || '',
        companyVatId: this.props.user.companyVatId || '',
        companyTaxId: this.props.user.companyTaxId || '',
        microphone: this.props.user.microphone || '',
        soundCard: this.props.user.soundCard || '',
        recordingSoftware: this.props.user.recordingSoftware || '',
        recordingSpace: this.props.user.recordingSpace || '',
        isEmailVerified: this.props.user.isEmailVerified ? 'yes' : 'no',
        isVoice: this.props.user.isVoice ? 'yes' : 'no',
        isApprovedVoice: this.props.user.isApprovedVoice ? 'yes' : 'no',
        voiceStatus: this.props.user.voiceStatus,
        hasStripePayout: this.props.user.hasStripePayout ? 'yes' : 'no',
        hasReverseVatReporting: this.props.user.hasReverseVatReporting ? 'yes' : 'no',
        internationalBankTransferInfo: this.props.user.internationalBankTransferInfo || '',
        invoiceEmail: this.props.user.invoiceEmail || '',
        hidePayouts: this.props.user.hidePayouts ? 'yes' : 'no',
        manualInvoice: this.props.user.manualInvoice ? 'yes' : 'no',
        allowedProjects: this.props.user.allowedProjects || [],
        selectedProject: null,
        labels: this.props.user.labels || [],
        stripeAccountId: this.props.user.stripe ? this.props.user.stripe.accountId : ''
      })
    }
  }

  savePersonalInfo = () => {
    updateUser(this.props.userId, {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      gender: this.state.gender,
      isEmailVerified: this.state.isEmailVerified === 'yes',
      isVoice: this.state.isVoice === 'yes',
      notes: this.state.notes,
      manualInvoice: this.state.manualInvoice === 'yes'
    })
  }

  deleteLabel = (label) => {
    updateUser(this.props.userId, {
      labels: this.props.user.labels.filter((l) => l !== label)
    })
  }

  saveCompanyInfo = () => {
    updateUser(this.props.userId, {
      companyName: this.state.companyName,
      companyAddressLine1: this.state.companyAddressLine1,
      companyAddressLine2: this.state.companyAddressLine2,
      companyPostalCode: this.state.companyPostalCode,
      companyCity: this.state.companyCity,
      companyCountry: this.state.companyCountry,
      companyHomepage: this.state.companyHomepage,
      companyVatId: this.state.companyVatId,
      companyTaxId: this.state.companyTaxId,
      invoiceEmail: this.state.invoiceEmail
    })
  }

  saveVoiceInfo = () => {
    updateUser(this.props.userId, {
      isApprovedVoice: this.state.isApprovedVoice === 'yes',
      voiceStatus: this.state.voiceStatus,
      hasStripePayout: this.state.hasStripePayout === 'yes',
      hasReverseVatReporting: this.state.hasReverseVatReporting === 'yes',
      hidePayouts: this.state.hidePayouts === 'yes',
      microphone: this.state.microphone,
      soundCard: this.state.soundCard,
      recordingSoftware: this.state.recordingSoftware,
      recordingSpace: this.state.recordingSpace,
      stripeAccountId: this.state.stripeAccountId,
      internationalBankTransferInfo: this.state.internationalBankTransferInfo,
      invoiceEmail: this.state.invoiceEmail
    })
  }

  saveAdminSettings = () => {
    updateUser(this.props.userId, {
      allowedProjects: this.state.allowedProjects
    })
  }

  addAllowedProject = (newProjectId) => {
    this.setState({
      allowedProjects: [
        ...this.state.allowedProjects,
        this.props.user.projectTemplates
          .reduce((arr, curr) => {
            arr.push({ id: curr.id, name: curr.name })
            return arr
          }, [])
          .find((p) => p.id === newProjectId)
      ],
      selectedProject: null
    })
  }

  removeAllowedProject = (projectId) => {
    this.setState({
      allowedProjects: this.state.allowedProjects.filter((p) => p.id !== projectId)
    })
  }

  loginAs = async (userId) => {
    const url = await getMagicLink(userId)
    window.open(url, '_blank')
  }

  render() {
    const { isUpdatingUser } = this.props

    const {
      firstName,
      lastName,
      email,
      gender,
      phone,
      notes,
      companyName,
      companyAddressLine1,
      companyAddressLine2,
      companyPostalCode,
      companyCity,
      companyCountry,
      companyVatId,
      companyTaxId,
      companyHomepage,
      isEmailVerified,
      isVoice,
      voiceStatus,
      hasStripePayout,
      hasReverseVatReporting,
      hidePayouts,
      microphone,
      soundCard,
      recordingSoftware,
      recordingSpace,
      manualInvoice,
      allowedProjects,
      selectedProject,
      labels,
      stripeAccountId,
      internationalBankTransferInfo,
      invoiceEmail
    } = this.state

    let paymentSettings

    if (isVoice === 'yes') {
      if (hasStripePayout === 'yes') {
        paymentSettings = (
          <Block>
            <Input
              label="Stripe account ID"
              value={stripeAccountId}
              onChange={(stripeAccountId) => this.setState({ stripeAccountId })}
            />
            <Block padding="0 30px">
              <a
                target="_blank"
                href={`https://dashboard.stripe.com/connect/accounts/${stripeAccountId}`}
              >
                Open in Stripe
              </a>
            </Block>
          </Block>
        )
      } else {
        paymentSettings = (
          <TextArea
            label="International bank transfer info"
            rows={4}
            value={internationalBankTransferInfo}
            onChange={(internationalBankTransferInfo) =>
              this.setState({ internationalBankTransferInfo })
            }
          />
        )
      }
    }

    return (
      <Block flex="1">
        <SpacerVertical />
        <Block margin="0 30px">
          <Button small onClick={() => this.loginAs(this.props.userId)}>
            Login as {this.props.user.name}
          </Button>
        </Block>
        <SpacerVertical />
        <Section>Labels</Section>
        <Row margin="0 30px" gap="10px" flexWrap="wrap">
          {labels.map((label) => {
            return (
              <Row
                alignItems="center"
                border="solid 1px #ccc"
                padding="0 10px"
                borderRadius="20px"
                gap="10px"
                whiteSpace="nowrap"
                userSelect="none"
              >
                <Block>{label}</Block>
                <Block props={{ onClick: () => this.deleteLabel(label) }} cursor="pointer">
                  <CloseIcon color="black" />
                </Block>
              </Row>
            )
          })}
          <Button small onClick={() => route(`/users/${this.props.user.id}/add-label`)}>
            Add label
          </Button>
        </Row>
        <SpacerVertical />
        <Section>Personal information</Section>
        <Block font-size="12px" padding="0 30px" color="rgb(167,167,167)">
          Hubspot contact
        </Block>
        <Block padding="0 30px">
          {this.state.fetchingHubspotLink && <Loading stroke="#000" />}
          {this.state.hubspotLink && (
            <a target="_blank" href={this.state.hubspotLink}>
              {this.state.hubspotLink}
            </a>
          )}
          {!this.state.fetchingHubspotLink && !this.state.hubspotLink && <Block>N/A</Block>}
        </Block>
        <SpacerVertical />
        <TextArea
          label="Notes"
          resizable={false}
          rows="4"
          value={notes}
          onChange={(notes) => this.setState({ notes })}
        />
        <SpacerVertical />
        <Input
          label="First name"
          value={firstName}
          onChange={(firstName) => this.setState({ firstName })}
        />
        <SpacerVertical />
        <Input
          label="Last name"
          value={lastName}
          onChange={(lastName) => this.setState({ lastName })}
        />
        <SpacerVertical />
        <Input label="Email" value={email} onChange={(email) => this.setState({ email })} />
        <SpacerVertical />
        <Select
          label="Gender"
          options={[
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'unknown', label: 'Unknown' }
          ]}
          value={gender}
          onChange={(gender) => this.setState({ gender })}
        />
        <SpacerVertical />
        <Input label="Phone" value={phone} onChange={(phone) => this.setState({ phone })} />
        <SpacerVertical />
        <Select
          label="Email verified"
          options={yesNoOptions}
          value={isEmailVerified}
          onChange={(isEmailVerified) => this.setState({ isEmailVerified })}
        />
        <SpacerVertical />
        <Select
          label="Is voice"
          options={yesNoOptions}
          value={isVoice}
          onChange={(isVoice) => this.setState({ isVoice })}
        />
        <SpacerVertical />
        {isVoice === 'no' && [
          <Select
            label="Manual invoice"
            options={yesNoOptions}
            value={manualInvoice}
            onChange={(manualInvoice) => this.setState({ manualInvoice })}
          />,
          <SpacerVertical />
        ]}
        <Row justifyContent="center">
          <Button primary loading={isUpdatingUser} onClick={this.savePersonalInfo}>
            Save personal information
          </Button>
        </Row>
        <SpacerVertical />
        <Section>Company information</Section>
        <SpacerVertical />
        <Input
          label="Company name"
          value={companyName}
          onChange={(companyName) => this.setState({ companyName })}
        />
        <SpacerVertical />
        <Input
          label="Address line 1"
          value={companyAddressLine1}
          onChange={(companyAddressLine1) => this.setState({ companyAddressLine1 })}
        />
        <SpacerVertical />
        <Input
          label="Address line 2"
          value={companyAddressLine2}
          onChange={(companyAddressLine2) => this.setState({ companyAddressLine2 })}
        />
        <SpacerVertical />
        <Input
          label="Postal code"
          value={companyPostalCode}
          onChange={(companyPostalCode) => this.setState({ companyPostalCode })}
        />
        <SpacerVertical />
        <Input
          label="City"
          value={companyCity}
          onChange={(companyCity) => this.setState({ companyCity })}
        />
        <SpacerVertical />
        <Select
          label="Country"
          value={companyCountry ? companyCountry.toLowerCase() : ''}
          options={getCountryOptions()}
          onChange={(companyCountry) => this.setState({ companyCountry })}
        />
        <SpacerVertical />
        <Input
          label="Homepage"
          value={companyHomepage}
          onChange={(companyHomepage) => this.setState({ companyHomepage })}
        />
        <SpacerVertical />
        <Input
          label="TAX ID"
          value={companyTaxId}
          onChange={(companyTaxId) => this.setState({ companyTaxId })}
        />
        <SpacerVertical />
        <Input
          label="VAT ID"
          value={companyVatId}
          onChange={(companyVatId) => this.setState({ companyVatId })}
        />
        <SpacerVertical />
        {isVoice === 'no' && [
          <Input
            label="Invoice email"
            value={invoiceEmail}
            onChange={(invoiceEmail) => this.setState({ invoiceEmail })}
          />,
          <SpacerVertical />
        ]}
        <Row justifyContent="center">
          <Button primary loading={isUpdatingUser} onClick={this.saveCompanyInfo}>
            Save company information
          </Button>
        </Row>
        {isVoice === 'yes' && [
          <SpacerVertical />,
          <Section>Voice information</Section>,
          <SpacerVertical />,
          <Select
            label="Status"
            options={[
              { value: 'pending', label: 'Pending' },
              { value: 'published', label: 'Published' },
              { value: 'unpublished', label: 'Unpublished' },
              { value: 'declined', label: 'Declined' }
            ]}
            value={voiceStatus}
            onChange={(voiceStatus) => this.setState({ voiceStatus })}
          />,
          <SpacerVertical />,
          <Select
            label="Stripe payout"
            options={yesNoOptions}
            value={hasStripePayout}
            onChange={(hasStripePayout) => this.setState({ hasStripePayout })}
          />,
          <SpacerVertical />,
          <Select
            label="Reverse VAT reporting"
            options={yesNoOptions}
            value={hasReverseVatReporting}
            onChange={(hasReverseVatReporting) => this.setState({ hasReverseVatReporting })}
          />,
          <SpacerVertical />,
          <Select
            label="Hide payouts"
            options={yesNoOptions}
            value={hidePayouts}
            onChange={(hidePayouts) => this.setState({ hidePayouts })}
          />,
          <SpacerVertical />,
          <TextArea
            label="Microphone"
            height="80px"
            value={microphone}
            onChange={(microphone) => this.setState({ microphone })}
          />,
          <SpacerVertical />,
          <TextArea
            label="Sound card"
            height="80px"
            value={soundCard}
            onChange={(soundCard) => this.setState({ soundCard })}
          />,
          <SpacerVertical />,
          <TextArea
            label="Recording software"
            height="80px"
            value={recordingSoftware}
            onChange={(recordingSoftware) => this.setState({ recordingSoftware })}
          />,
          <SpacerVertical />,
          <TextArea
            label="Recording space"
            height="80px"
            value={recordingSpace}
            onChange={(recordingSpace) => this.setState({ recordingSpace })}
          />,
          <SpacerVertical />,
          paymentSettings,
          <SpacerVertical />,
          <Row justifyContent="center">
            <Button primary loading={isUpdatingUser} onClick={this.saveVoiceInfo}>
              Save voice info
            </Button>
          </Row>
        ]}
        <SpacerVertical />
        {!this.props.user.isVoice && (
          <Fragment>
            <Section>Admin settings</Section>
            <SpacerVertical />

            <Row>
              <Select
                label="Add new allowed project"
                options={this.props.user.projectTemplates.map((p) => ({
                  value: p.id,
                  label: p.name
                }))}
                value={selectedProject}
                onChange={(selectedProject) => this.setState({ selectedProject })}
              />

              <Button
                disabled={!selectedProject}
                onClick={() => this.addAllowedProject(selectedProject)}
              >
                Add to allowed projects
              </Button>
            </Row>
            <SpacerVertical />

            {allowedProjects.length === 0 && <Block margin="0 30px">All projects allowed</Block>}

            {allowedProjects.length > 0 && (
              <Fragment>
                <Row margin="0 30px">Allowed projects</Row>
                <SpacerVertical />
                <Row margin="0 40px">
                  <ul>
                    {allowedProjects.map((p) => (
                      <li>
                        {p.name}{' '}
                        <InlineBlock>
                          <Button small onClick={() => this.removeAllowedProject(p.id)}>
                            remove
                          </Button>
                        </InlineBlock>
                      </li>
                    ))}
                  </ul>
                </Row>
              </Fragment>
            )}

            <SpacerVertical />
            <Row justifyContent="center">
              <Button primary loading={isUpdatingUser} onClick={this.saveAdminSettings}>
                Save admin settings
              </Button>
            </Row>
          </Fragment>
        )}
        <SpacerVertical />
      </Block>
    )
  }
}

export default connect('user, isUpdatingUser')(EditUser)
