import { h, Component } from 'preact'

import { Switch } from '../../router'

import SearchProjects from './SearchProjects'
import Project from './Project'

class Projects extends Component {
  render() {
    return (
      <Switch>
        <Project path="/projects/:projectId" />
        <SearchProjects />
      </Switch>
    )
  }
}

export default Projects
