import { h } from 'preact'

export const Loading = ({ size = '20', stroke = '#fff' }) => (
  <svg
    width={size}
    height={size}
    viewBox="-2 -2 42 42"
    xmlns="http://www.w3.org/2000/svg"
    stroke={stroke}
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="4">
        <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.85s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
)
