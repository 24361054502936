import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Block,
  Button,
  Input,
  Row,
  SpacerHorizontal,
  SpacerVertical
} from '@voicemachine/preact-ui'

import { goBack, route } from '../../router'
import { createProjectTemplate } from '../../actions'

class CreateProjectTemplate extends Component {
  async save() {
    const { name } = this.state

    if (name && name.trim() !== '') {
      const projectTemplate = await createProjectTemplate({ name })
      if (projectTemplate) {
        route(`/project-templates/${projectTemplate.id}`)
      }
    }
  }

  render() {
    const { isCreatingProjectTemplate } = this.props
    const { name = '' } = this.state

    return (
      <Block padding="10px">
        <Input label="Name" value={name} onChange={(name) => this.setState({ name })} />
        <SpacerVertical />
        <Row alignItems="center" padding="0 30px">
          <Button loading={isCreatingProjectTemplate} onClick={() => this.save()}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button disabled={isCreatingProjectTemplate} onClick={() => goBack('/project-templates')}>
            Cancel
          </Button>
        </Row>
      </Block>
    )
  }
}

export default connect('isCreatingProjectTemplate')(CreateProjectTemplate)
