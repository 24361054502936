import { h, Component } from 'preact'
import { Row } from './blocks'

export class RoundButton extends Component {
  state = {
    focus: false
  }
  handleFocus = () => this.setState({ focus: true })
  handleBlur = () => this.setState({ focus: false })

  render() {
    const { focus } = this.state
    const { children, onClick, large, ...props } = this.props

    const size = large ? '52px' : '45px'

    return (
      <Row
        component="button"
        border="none"
        outline="none"
        overflow="hidden"
        onClick={onClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        {...props}
      >
        <Row
          alignItems="center"
          justifyContent="center"
          width={size}
          height={size}
          borderRadius="50%"
          background={focus ? 'rgba(234, 90, 153, 0.075)' : 'none'}
        >
          {children}
        </Row>
      </Row>
    )
  }
}
