import { h } from 'preact'
import { SpacerVertical, SpacerHorizontal } from './spacers'
import { Row, Block } from './blocks'
import { InfoRoundIcon } from './icons'

export const InputError = ({ children, large = false }) => {
  if (!children || children.length === 0) {
    return null
  }
  return (
    <Block>
      <SpacerVertical tiny />
      <Row
        fontSize={large ? '15px' : '12px'}
        lineHeight={large ? '18px' : '14px'}
        color="red"
        alignItems="flex-start"
      >
        <InfoRoundIcon fill="red" size="20" margin-top="-2px" />
        <SpacerHorizontal tiny />
        <Block flex="1">{children}</Block>
      </Row>
    </Block>
  )
}
