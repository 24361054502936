import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Row } from 'jsxstyle/preact'
import dateFormat from 'dateformat'
import { Button, PdfIcon, ToggleSwitch } from '@voicemachine/preact-ui'

import { route } from '../router'
import { fetchManualInvoices, updateManualInvoice } from '../actions'

import { Table, TableData, TableHeader, TableRow } from './Table'
import GenerateInvoiceInfoDialog from './GenerateInvoiceInfoDialog'

class ManualInvoices extends Component {
  componentDidMount() {
    fetchManualInvoices()
    const showDone = localStorage.getItem('showDone') === 'true'
    this.setState({ showDone, selected: [] })
  }

  componentDidUpdate(_, prevState) {
    if (prevState.showDone !== this.state.showDone) {
      localStorage.setItem('showDone', this.state.showDone)
    }
  }

  setDone = async (invoiceId, done) => {
    updateManualInvoice(invoiceId, { done })
  }

  setAllSelected = (allSelected) => {
    if (allSelected) {
      const { manualInvoices = [] } = this.props
      this.setState({ selected: manualInvoices.map((invoice) => invoice.id) })
    } else {
      this.setState({ selected: [] })
    }
  }

  toggleSelected = (invoiceId) => {
    const { selected = [] } = this.state
    if (selected.includes(invoiceId)) {
      this.setState({ selected: selected.filter((id) => id !== invoiceId) })
    } else {
      this.setState({ selected: [...selected, invoiceId] })
    }
  }

  render() {
    const { manualInvoices = [] } = this.props
    const { selected = [], generateInvoiceInfoVisible } = this.state

    const allSelected =
      manualInvoices.length > 0 && manualInvoices.every((invoice) => selected.includes(invoice.id))

    const selectedProjectIds = selected.map(
      (invoiceId) => manualInvoices.find((invoice) => invoice.id === invoiceId).project.id
    )

    return (
      <Block padding="10px">
        <Row alignItems="center" marginLeft="-20px">
          <ToggleSwitch
            on={this.state.showDone}
            onChange={(showDone) => this.setState({ showDone })}
            label="Show done"
          />
          <Button
            small
            disabled={selected.length === 0}
            onClick={() => this.setState({ generateInvoiceInfoVisible: true })}
          >
            Generate invoice specification
          </Button>
        </Row>
        <Table width="auto">
          <TableRow>
            <TableHeader>
              <input
                type="checkbox"
                checked={allSelected}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setAllSelected(!allSelected)
                }}
              />
            </TableHeader>
            <TableHeader>Project</TableHeader>
            <TableHeader>PO/ref</TableHeader>
            <TableHeader>Client</TableHeader>
            <TableHeader textAlign="right">Excl VAT</TableHeader>
            <TableHeader textAlign="right">Incl VAT</TableHeader>
            <TableHeader textAlign="right">VAT</TableHeader>
            <TableHeader>Project accepted</TableHeader>
            <TableHeader>Project approved</TableHeader>
            <TableHeader>Invoice number</TableHeader>
            <TableHeader>Invoice specification</TableHeader>
            <TableHeader>Done</TableHeader>
          </TableRow>
          {manualInvoices
            .filter((invoice) => !invoice.done || this.state.showDone)
            .map((invoice) => {
              return (
                <TableRow
                  hoverBackground="#fafafa"
                  onClick={() => route(`/manual-invoices/${invoice.id}`)}
                >
                  <TableData>
                    <input
                      type="checkbox"
                      checked={selected.includes(invoice.id)}
                      onClick={(e) => {
                        e.stopPropagation()
                        this.toggleSelected(invoice.id)
                      }}
                    />
                  </TableData>
                  <TableData>
                    <a
                      href={`/projects/${invoice.project.id}`}
                      style="padding:10px"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        route(`/projects/${invoice.project.id}`)
                      }}
                    >
                      {invoice.project.name}
                    </a>
                  </TableData>
                  <TableData>{invoice.project.purchaseOrder}</TableData>
                  <TableData>
                    <a
                      href={`/users/${invoice.user.id}`}
                      style="padding:10px"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        route(`/users/${invoice.user.id}`)
                      }}
                    >
                      {invoice.user.name}
                    </a>
                  </TableData>
                  <TableData textAlign="right">{invoice.amountExclVat}</TableData>
                  <TableData textAlign="right">{invoice.amountInclVat}</TableData>
                  <TableData textAlign="right">{invoice.vat}</TableData>
                  <TableData whiteSpace="nowrap">
                    {dateFormat(invoice.created, 'yyyy-mm-dd')}
                  </TableData>
                  <TableData whiteSpace="nowrap">
                    {invoice.project.approvedDate
                      ? dateFormat(invoice.project.approvedDate, 'yyyy-mm-dd')
                      : ''}
                  </TableData>
                  <TableData>{invoice.invoiceNumber}</TableData>
                  {invoice.invoiceInfoUrl && (
                    <TableData>
                      <a
                        onClick={(e) => e.stopPropagation()}
                        href={invoice.invoiceInfoUrl}
                        target="_blank"
                      >
                        Invoice specification
                      </a>
                    </TableData>
                  )}
                  {!invoice.invoiceInfoUrl && <TableData></TableData>}
                  <TableData whiteSpace="nowrap" onClick={(e) => e.stopPropagation()}>
                    <Row alignItems="center" marginLeft="-30px">
                      <ToggleSwitch
                        on={invoice.done}
                        onChange={(done) => this.setDone(invoice.id, done)}
                      />
                    </Row>
                  </TableData>
                </TableRow>
              )
            })}
        </Table>
        {generateInvoiceInfoVisible && (
          <GenerateInvoiceInfoDialog
            invoices={manualInvoices.filter((invoice) => selected.includes(invoice.id))}
            onSuccess={() => {
              fetchManualInvoices()
              this.setState({ selected: [] })
            }}
            onClose={() => this.setState({ generateInvoiceInfoVisible: false })}
          />
        )}
      </Block>
    )
  }
}

export default connect('manualInvoices, isFetchingManualInvoices')(ManualInvoices)
