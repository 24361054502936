import { h, Component } from 'preact'
import { Block, Col } from './blocks'
import { Label } from './Label'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'

export class TextArea extends Component {
  state = {
    focus: false
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.textarea.focus()
    }
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  handleFocus = () => {
    this.setState({ focus: true })
  }

  handleBlur = () => {
    this.setState({ focus: false })
  }

  render() {
    const {
      label,
      placeholder,
      value,
      infoText,
      errorText,
      height,
      disabled,
      borderless,
      rows,
      large = false
    } = this.props
    const { focus } = this.state

    return (
      <Block
        width="calc(100% - 60px)"
        margin="0 30px"
        boxSizing="border-box"
        flex="1"
        display="flex"
        flexDirection="column"
      >
        <Col component="label" position="relative" flex="1">
          {label && (
            <Label large={large} error={errorText}>
              {label}
            </Label>
          )}
          <Block
            component="textArea"
            flex="1"
            boxSizing="border-box"
            fontFamily="helvetica neue, arial, sans-serif"
            fontSize={large ? '20px' : '16px'}
            lineHeight={large ? '24px' : '18px'}
            width="100%"
            height={height !== 'undefined' ? height : '100%'}
            padding={borderless ? '0' : large ? '7px 12px' : '5px 10px'}
            background="#fafafa"
            color={disabled ? '#999' : '#000'}
            outline="none"
            border={borderless ? 'none' : focus ? 'solid 2px #EA5A99' : 'solid 1px #a7a7a7'}
            transition="border .15s"
            props={{
              value,
              placeholder,
              disabled,
              rows: rows,
              resize: 'none',
              onInput: this.handleChange,
              onFocus: this.handleFocus,
              onBlur: this.handleBlur,
              ref: (textarea) => (this.textarea = textarea)
            }}
          />
        </Col>
        {!errorText && <InputInfo>{infoText}</InputInfo>}
        {errorText && <InputError>{errorText}</InputError>}
      </Block>
    )
  }
}
