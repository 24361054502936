import { h, Component } from 'preact'
import dateFormat from 'dateformat'
import { connect } from 'unistore/preact'
import { Block, Button, Input, SpacerVertical } from '@voicemachine/preact-ui'
import { Row } from 'jsxstyle/preact'
import { generateInvoiceInfo } from '../actions'
import { get } from '../api'

const calcInvoicePeriod = (invoices) => {
  let fromDate
  let toDate
  for (let invoice of invoices) {
    const createdDate = new Date(invoice.created)
    if (!fromDate || createdDate < fromDate) {
      fromDate = new Date(createdDate)
    }
    if (!toDate || createdDate > toDate) {
      toDate = new Date(createdDate)
    }
  }

  if (!fromDate || !toDate) {
    return ''
  }

  fromDate.setUTCDate(1)
  toDate.setUTCDate(1)
  toDate.setUTCMonth(toDate.getUTCMonth() + 1)
  toDate.setUTCDate(0)

  return dateFormat(fromDate, 'yyyy-mm-dd') + ' – ' + dateFormat(toDate, 'yyyy-mm-dd')
}

class GenerateInvoiceInfoDialog extends Component {
  componentDidMount() {
    const { invoices } = this.props

    this.setState({
      invoicePeriod: calcInvoicePeriod(invoices)
    })

    get('/exchange-rates').then(({ data: rates }) => {
      this.setState({ exchangeRate: rates.SEK })
    })
  }

  onSubmit = async () => {
    const { invoices, onSuccess, onClose } = this.props
    const { invoiceNumber, invoicePeriod, exchangeRate } = this.state

    const invoiceIds = invoices.map((invoice) => invoice.id)

    if (await generateInvoiceInfo({ invoiceIds, invoiceNumber, invoicePeriod, exchangeRate })) {
      if (onSuccess) {
        await onSuccess()
      }
      onClose()
    }
  }

  render() {
    const { isGeneratingInvoiceInfo, onClose } = this.props
    const { invoiceNumber, invoicePeriod, exchangeRate } = this.state
    return (
      <Block
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="rgba(0, 0, 0, 0.5)"
        ref={(elem) => (this.elem = elem)}
        onClick={(e) => {
          if (e.target === this.elem.base) {
            onClose()
          }
        }}
      >
        <Block background="white">
          <SpacerVertical />
          <Input
            label="Invoice number"
            value={invoiceNumber}
            onChange={(invoiceNumber) => this.setState({ invoiceNumber })}
          />
          <SpacerVertical />
          <Input
            label="Invoice period"
            value={invoicePeriod}
            onChange={(invoicePeriod) => this.setState({ invoicePeriod })}
          />
          <SpacerVertical />
          <Input
            label="Exchange rate"
            value={exchangeRate}
            onChange={(exchangeRate) => this.setState({ exchangeRate })}
          />
          <SpacerVertical />
          <Row alignItems="center" gap="5px" padding="0 30px">
            <Button small loading={isGeneratingInvoiceInfo} onClick={this.onSubmit}>
              Generate
            </Button>
            <Button small onClick={onClose}>
              Cancel
            </Button>
          </Row>
          <SpacerVertical />
        </Block>
      </Block>
    )
  }
}

export default connect('isGeneratingInvoiceInfo')(GenerateInvoiceInfoDialog)
