import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { fetchMessages, postSupportMessage, deleteChatMessage } from '../../../actions'
import { Button, Row, SpacerVertical } from '@voicemachine/preact-ui'
import { get } from '../../../api'
import { goBack } from '../../../router'

class ChatView extends Component {
  state = {
    newMessage: null
  }

  componentDidMount = () => {
    fetchMessages(this.props.voice.id)
  }

  sendSupportMessage = async () => {
    if (this.state.newMessage.trim() === '') return

    await postSupportMessage(this.props.voice.id, this.state.newMessage)
    fetchMessages(this.props.voice.id)

    this.setState({
      newMessage: null
    })
  }

  handleDeleteChatMessage = async (message) => {
    if (confirm(`Delete chat message "${message.text || message.type}"?`)) {
      deleteChatMessage({
        voiceId: this.props.voice.id,
        timestamp: message.timestamp,
        type: message.type
      })
    }
  }

  render() {
    if (!this.props.messages) return null

    const projectMessages = this.props.messages[this.props.project.id]
    if (!projectMessages) return null

    const voiceMessages = projectMessages[this.props.voice.id]
    if (!voiceMessages) return null

    const readOnly = this.props.readOnly

    return (
      <div>
        {voiceMessages.map((message) => {
          return (
            <div>
              {message.type && `${message.type} - `} {message.timestamp}{' '}
              {message.text && (
                <span>
                  {message.from && <strong>{message.from.name}:</strong>}
                  {!message.uri && message.text}
                  {message.uri && <audio controls src={message.uri} />}
                </span>
              )}
              <a
                href=""
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  this.handleDeleteChatMessage(message)
                }}
              >
                Delete
              </a>
            </div>
          )
        })}
        {!readOnly && (
          <Row>
            <textarea
              rows="4"
              cols="50"
              value={this.state.newMessage}
              onChange={(e) => this.setState({ newMessage: e.target.value })}
            ></textarea>
            <Button loading={this.props.isPostingMessages} onClick={this.sendSupportMessage} small>
              Send support message
            </Button>
          </Row>
        )}
      </div>
    )
  }
}

const Chat = connect('project, messages, isPostingMessages')(ChatView)

class ProjectChat extends Component {
  componentDidMount = () => {
    const project = this.props.project
    get(`/projects/${project.id}/voices`).then(({ data: voices }) => this.setState({ voices }))
  }

  render() {
    const { voices } = this.state

    if (!voices) {
      return <div>Loading chat...</div>
    }

    const voice = voices.find((voice) => voice.id === this.props.voiceId)

    const readOnly = this.props.project.voices.every((v) => v.id !== voice.id)

    return (
      <div>
        <SpacerVertical />
        <Button small onClick={() => goBack(`/projects/${this.props.project.id}/chat`)}>
          Back to chats
        </Button>
        <h2>{voice.name}</h2>
        {readOnly && (
          <div>
            <div style="color:red">(Read only - voice is no longer shortlisted)</div>
            <br />
          </div>
        )}
        <Chat voice={voice} readOnly={readOnly} />
        <SpacerVertical large />
      </div>
    )
  }
}

export default connect('project, messages')(ProjectChat)
