import { store } from '../store'
import { post, patch, get, del } from '../api'
import { route } from '../router'

export const setState = (state) => {
  store.setState(state)
}

export const verifyLoggedIn = async () => {
  try {
    store.setState({ isVerifyingLoggedIn: true })
    const { data: email } = await get('/me')
    store.setState({ isVerifyingLoggedIn: false, email })
  } catch (error) {
    store.setState({ isVerifyingLoggedIn: false, error })
  }
}

export const logout = async () => {
  await post('/logout')
  store.setState({ email: null })
  route('/')
  return true
}

export const setUserSearchParams = (params, replace) => {
  if (replace) {
    store.setState({ userSearchParams: params })
  } else {
    const { userSearchParams } = store.getState()
    store.setState({ userSearchParams: { ...userSearchParams, ...params } })
  }
}

export const setProjectSearchParams = (params, replace) => {
  if (replace) {
    store.setState({ projectSearchParams: params })
  } else {
    const { projectSearchParams } = store.getState()
    store.setState({ projectSearchParams: { ...projectSearchParams, ...params } })
  }
}

export const fetchUsers = async (params) => {
  try {
    store.setState({ isFetchingUsers: true })
    const { data: users } = await get('/users', params)
    store.setState({ isFetchingUsers: false, users })
  } catch (error) {
    store.setState({ isFetchingUsers: false, error })
  }
}

export const fetchLabels = async () => {
  try {
    store.setState({ isFetchingLabels: true })
    const { data: labels } = await get('/users/labels')
    store.setState({ isFetchingLabels: false, labels })
  } catch (error) {
    store.setState({ isFetchingLabels: false, error })
  }
}

export const fetchManualInvoices = async (params) => {
  try {
    store.setState({ isFetchingManualInvoices: true })
    const { data: manualInvoices } = await get('/manual-invoices', params)
    store.setState({ isFetchingManualInvoices: false, manualInvoices })
  } catch (error) {
    store.setState({ isFetchingManualInvoices: false, error })
  }
}

export const updateManualInvoice = async (invoiceId, params) => {
  try {
    store.setState({ isUpdatingManualInvoice: true })
    const { data: manualInvoices } = await patch(`/manual-invoices/${invoiceId}`, params)
    store.setState({ isUpdatingManualInvoice: false, manualInvoices })
    return true
  } catch (error) {
    store.setState({ isUpdatingManualInvoice: false, error })
  }
}

export const fetchUser = async (userId) => {
  try {
    store.setState({ isFetchingUser: true })
    const { data: user } = await get(`/users/${userId}`)
    store.setState({ isFetchingUser: false, user })
  } catch (error) {
    store.setState({ isFetchingUser: false, error })
  }
}

export const updateUser = async (userId, params) => {
  try {
    store.setState({ isUpdatingUser: true })
    const { data: user } = await patch(`/users/${userId}`, params)
    const users = store.getState().users || []
    store.setState({
      isUpdatingUser: false,
      users: users.map((u) => (u.id === user.id ? user : u)),
      user
    })
    await fetchLabels()
    return true
  } catch (error) {
    store.setState({ isUpdatingUser: false, error })
  }
}

export const fetchProjects = async (params) => {
  try {
    store.setState({ isFetchingProjects: true })
    const { data: projects } = await get('/projects', params)
    store.setState({ isFetchingProjects: false, projects })
  } catch (error) {
    store.setState({ isFetchingProjects: false, error })
  }
}

export const fetchProject = async (projectId) => {
  try {
    store.setState({ isFetchingProject: true })
    const { data: project } = await get(`/projects/${projectId}`)
    store.setState({ isFetchingProject: false, project })
  } catch (error) {
    store.setState({ isFetchingProject: false, error })
  }
}

export const updateProject = async (projectId, params) => {
  store.setState({ isUpdatingProject: true })
  try {
    const { data: project } = await patch(`/projects/${projectId}`, params)
    const { projects = [] } = store.getState()
    store.setState({
      isUpdatingProject: false,
      project,
      projects: projects.map((p) => (p.id === project.id ? project : p))
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingProject: false, error })
    return false
  }
}

export const generateInvoiceInfo = async (params) => {
  store.setState({ isGeneratingInvoiceInfo: true })
  try {
    await post(`/generate-invoice-info`, params)
    store.setState({ isGeneratingInvoiceInfo: false })
    return true
  } catch (error) {
    store.setState({ isGeneratingInvoiceInfo: false, error })
    return false
  }
}

export const updateCard = async (userId, cardId, params) => {
  store.setState({ isUpdatingCard: true })
  try {
    const { data: user } = await patch(`/users/${userId}/cards/${cardId}`, params)
    store.setState({
      isUpdatingCard: false,
      user
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingCard: false, error })
    return false
  }
}

export const deleteCard = async (userId, cardId) => {
  store.setState({ isDeletingCard: true })
  try {
    const { data: user } = await del(`/users/${userId}/cards/${cardId}`)
    store.setState({
      isDeletingCard: false,
      user
    })
    return true
  } catch (error) {
    store.setState({ isDeletingCard: false, error })
    return false
  }
}

export const updateVoiceDemo = async (audioId, params) => {
  store.setState({ isUpdatingVoiceDemo: true })
  try {
    const { data: voiceDemo } = await patch(`/voice-demos/${audioId}`, params)
    const { user } = store.getState()
    store.setState({
      isUpdatingVoiceDemo: false,
      user: {
        ...user,
        voiceDemos: user.voiceDemos.map((v) => (v.id === audioId ? voiceDemo : v))
      }
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingVoiceDemo: false, error })
    return false
  }
}

export const deleteVoiceDemo = async (audioId) => {
  store.setState({ isDeletingVoiceDemo: true })
  try {
    const { user } = store.getState()
    await del(`/voice-demos/${audioId}`)
    store.setState({
      isDeletingVoiceDemo: false,
      user: {
        ...user,
        voiceDemos: user.voiceDemos.filter((v) => v.id !== audioId)
      }
    })
    return true
  } catch (error) {
    store.setState({ isDeletingVoiceDemo: false, error })
    return false
  }
}

export const deleteFile = async (projectId, audioId) => {
  store.setState({ isDeletingFile: true })
  try {
    const { data: project } = await del(`/projects/${projectId}/files/${audioId}`)
    const { projects = [] } = store.getState()
    store.setState({
      isDeletingFile: false,
      project,
      projects: projects.map((p) => (p.id === project.id ? project : p))
    })
    return true
  } catch (error) {
    store.setState({ isDeletingFile: false, error })
    return false
  }
}

export const deleteAudio = async (projectId, audioId) => {
  store.setState({ isDeletingAudio: true })
  try {
    const { data: project } = await del(`/projects/${projectId}/audio/${audioId}`)
    const { projects = [] } = store.getState()
    store.setState({
      isDeletingAudio: false,
      project,
      projects: projects.map((p) => (p.id === project.id ? project : p))
    })
    return true
  } catch (error) {
    store.setState({ isDeletingAudio: false, error })
    return false
  }
}

export const fetchStats = async () => {
  try {
    store.setState({ isFetchingStats: true })
    const { data: stats } = await get('/stats')
    store.setState({ isFetchingStats: false, stats })
  } catch (error) {
    store.setState({ isFetchingStats: false, error })
  }
}

export const fetchApiKeys = async () => {
  try {
    store.setState({ isFetchingApiKeys: true })
    const { data: apiKeys } = await get('/api-keys')
    store.setState({ isFetchingApiKeys: false, apiKeys })
  } catch (error) {
    store.setState({ isFetchingApiKeys: false, error })
  }
}

export const createApiKey = async (params) => {
  store.setState({ isCreatingApiKey: true })
  try {
    const { data: apiKeys } = await post('/api-keys', params)
    store.setState({
      isCreatingApiKey: false,
      apiKeys
    })
    return true
  } catch (error) {
    store.setState({ isCreatingApiKey: false, error })
    return false
  }
}

export const updateApiKey = async (value, params) => {
  store.setState({ isUpdatingApiKey: true })
  try {
    const { data: apiKeys } = await patch(`/api-keys/${value}`, params)
    store.setState({
      isUpdatingApiKey: false,
      apiKeys
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingApiKey: false, error })
    return false
  }
}
export const deleteApiKey = async (value) => {
  store.setState({ isDeletingApiKey: true })
  try {
    const { data: apiKeys } = await del(`/api-keys/${value}`)
    store.setState({
      isDeletingApiKey: false,
      apiKeys
    })
    return true
  } catch (error) {
    store.setState({ isDeletingApiKey: false, error })
    return false
  }
}

export const fetchProjectTemplates = async () => {
  try {
    store.setState({ isFetchingProjectTemplates: true })
    const { data: projectTemplates } = await get('/project-templates')
    store.setState({ isFetchingProjectTemplates: false, projectTemplates })
  } catch (error) {
    store.setState({ isFetchingProjectTemplates: false, error })
  }
}

export const fetchProjectTemplate = async (templateId) => {
  try {
    store.setState({ isFetchingProjectTemplate: true })
    const { data: projectTemplate } = await get(`/project-templates/${templateId}`)
    store.setState({ isFetchingProjectTemplate: false, projectTemplate })
    return true
  } catch (error) {
    store.setState({ isFetchingProjectTemplate: false, error })
    return false
  }
}

export const createProjectTemplate = async (params) => {
  store.setState({ isCreatingProjectTemplate: true })
  try {
    const { data: projectTemplate } = await post('/project-templates', params)
    store.setState({
      isCreatingProjectTemplate: false,
      projectTemplate
    })
    return projectTemplate
  } catch (error) {
    store.setState({ isCreatingProjectTemplate: false, error })
    return null
  }
}

export const updateProjectTemplate = async (params) => {
  store.setState({ isUpdatingProjectTemplate: true })
  try {
    const templateId = store.getState().projectTemplate.id
    const { data: projectTemplate } = await patch(`/project-templates/${templateId}`, params)
    store.setState({
      isUpdatingProjectTemplate: false,
      projectTemplate
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingProjectTemplate: false, error })
    return false
  }
}

export const deleteProjectTemplate = async (templateId) => {
  store.setState({ isDeletingProjectTemplate: true })
  try {
    await del(`/project-templates/${templateId}`)
    store.setState({
      isDeletingProjectTemplate: false,
      projectTemplates: store
        .getState()
        .projectTemplates.filter((template) => template.id !== templateId)
    })
    return true
  } catch (error) {
    store.setState({ isDeletingProjectTemplate: false, error })
    return false
  }
}

export const setProjectTemplateStatus = async (templateId, status) => {
  store.setState({ isUpdatingProjectTemplate: true })
  try {
    await patch(`/project-templates/${templateId}`, { status })
    store.setState({
      isUpdatingProjectTemplate: false,
      projectTemplates: store
        .getState()
        .projectTemplates.map((template) =>
          template.id === templateId ? { ...template, status } : template
        )
    })
    return true
  } catch (error) {
    store.setState({ isUpdatingProjectTemplate: false, error })
    return false
  }
}

export const createProjectTemplateAddon = async (params) => {
  store.setState({ isCreatingAddon: true })
  try {
    const { data: projectTemplate } = await post('/addons', params)
    store.setState({
      isCreatingAddon: false,
      projectTemplate
    })
    return projectTemplate
  } catch (error) {
    store.setState({ isCreatingAddon: false, error })
    return null
  }
}

export const updateProjectTemplateAddon = async (addonId, params) => {
  store.setState({ isUpdatingAddon: true })
  try {
    const { data: projectTemplate } = await patch(`/addons/${addonId}`, params)
    store.setState({
      isUpdatingAddon: false,
      projectTemplate
    })
    return projectTemplate
  } catch (error) {
    store.setState({ isUpdatingAddon: false, error })
    return null
  }
}

export const deleteProjectTemplateAddon = async (addonId) => {
  store.setState({ isDeletingAddon: true })
  try {
    await del(`/addons/${addonId}`)
    const projectTemplate = store.getState().projectTemplate
    store.setState({
      isDeletingAddon: false,
      projectTemplate: {
        ...projectTemplate,
        addons: projectTemplate.addons.filter((addon) => addon.id !== addonId)
      }
    })
    return true
  } catch (error) {
    store.setState({ isDeletingAddon: false, error })
    return null
  }
}

export const createProjectAddon = async (params) => {
  store.setState({ isCreatingAddon: true })
  try {
    const { data: project } = await post('/addons', params)
    store.setState({
      isCreatingAddon: false,
      project
    })
    return project
  } catch (error) {
    store.setState({ isCreatingAddon: false, error })
    return null
  }
}

export const updateProjectAddon = async (addonId, params) => {
  store.setState({ isUpdatingAddon: true })
  try {
    const { data: project } = await patch(`/addons/${addonId}`, params)
    store.setState({
      isUpdatingAddon: false,
      project
    })
    return project
  } catch (error) {
    store.setState({ isUpdatingAddon: false, error })
    return null
  }
}

export const deleteProjectAddon = async (addonId) => {
  store.setState({ isDeletingAddon: true })
  try {
    await del(`/addons/${addonId}`)
    const project = store.getState().project
    store.setState({
      isDeletingAddon: false,
      project: {
        ...project,
        addons: project.addons.filter((addon) => addon.id !== addonId)
      }
    })
    return true
  } catch (error) {
    store.setState({ isDeletingAddon: false, error })
    return null
  }
}

export const getMagicLink = async (userId) => {
  const { data } = await post(`/magic-user-link`, { userId })
  return data.url
}

export const deleteChatMessage = async ({ voiceId, type, timestamp }) => {
  try {
    store.setState({ isDeletingChatMessage: true })
    const projectId = store.getState().project.id
    const { data: messages } = await del(`/projects/${projectId}/messages`, {
      voiceId,
      type,
      timestamp
    })
    const currentMessages = store.getState().messages || {}
    store.setState({
      messages: {
        ...currentMessages,
        [projectId]: {
          ...currentMessages[projectId],
          [voiceId]: messages
        }
      },
      isDeletingChatMessage: false
    })
  } catch (error) {
    console.warn(error)
    store.setState({ error, isDeletingChatMessage: false })
  }
}

export const fetchMessages = async (voiceId) => {
  try {
    store.setState({ isFetchingMessages: true })
    const projectId = store.getState().project.id
    const { data: messages } = await get(`/projects/${projectId}/messages`, { voiceId })
    const currentMessages = store.getState().messages || {}
    store.setState({
      messages: {
        ...currentMessages,
        [projectId]: {
          ...currentMessages[projectId],
          [voiceId]: messages
        }
      },
      isFetchingMessages: false
    })
  } catch (error) {
    console.warn(error)
    store.setState({ error, isFetchingMessages: false })
  }
}

export const postSupportMessage = async (voiceId, text) => {
  try {
    store.setState({ isPostingMessages: true })
    const projectId = store.getState().project.id
    await post(`/projects/${projectId}/support-messages`, {
      voiceId,
      text
    })
    store.setState({ isPostingMessages: false })
  } catch (error) {
    console.warn(error)
    store.setState({ error, isPostingMessages: false })
  }
}
