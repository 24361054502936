import { h, Component } from 'preact'
import { Block } from './blocks'
import { Label } from './Label'
import { InputInfo } from './InputInfo'
import { InputError } from './InputError'

export class Input extends Component {
  state = {
    focus: false
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.input.focus()
    }
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  handleFocus = () => {
    this.setState({ focus: true })
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  handleBlur = () => {
    this.setState({ focus: false })
  }

  handleKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
    if (!this.props.readonly) {
      if (e.key === 'Enter' && this.props.onEnter) {
        this.props.onEnter(e.target.value)
      }
    }
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const {
      type = 'text',
      label,
      placeholder,
      value,
      infoText,
      errorText,
      readonly = false,
      disabled,
      autocomplete,
      large,
      maxWidth
    } = this.props

    const { focus } = this.state

    return (
      <Block
        boxSizing="border-box"
        width="calc(100% - 60px)"
        maxWidth={maxWidth ? maxWidth : 'none'}
        margin="0 30px"
      >
        <Block component="label" position="relative">
          {label && (
            <Label large={large} error={errorText}>
              {label}
            </Label>
          )}
          <Block
            component="input"
            boxSizing="border-box"
            width="100%"
            height={large ? '40px' : '35px'}
            props={{
              type,
              value,
              placeholder,
              disabled,
              readonly,
              autocomplete,
              onInput: !readonly ? this.handleChange : undefined,
              onFocus: this.handleFocus,
              onBlur: this.handleBlur,
              onKeyDown: this.handleKeyDown,
              onClick: this.handleClick,
              ref: (input) => (this.input = input)
            }}
            background="#fafafa"
            fontFamily="helvetica neue, arial, sans-serif"
            fontSize={large ? '20px' : '16px'}
            fontStyle={disabled ? 'italic' : 'normal'}
            lineHeight={large ? '24px' : '18px'}
            color={disabled ? '#999' : '#000'}
            outline="none"
            border="none"
          />
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom={errorText ? 'solid 1px red' : 'solid 1px #a7a7a7'}
          />
          <Block
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            borderBottom="solid 2px #EA5A99"
            opacity={focus ? '1' : '0'}
            transition="opacity .15s ease"
          />
        </Block>
        {!errorText && <InputInfo large={large}>{infoText}</InputInfo>}
        {errorText && <InputError large={large}>{errorText}</InputError>}
      </Block>
    )
  }
}
