import { h } from 'preact'
import { Block, Inline } from './blocks'

const getSize = ({ huge, large, small, tiny }) => {
  if (huge) {
    return '100px'
  }
  if (large) {
    return '40px'
  }
  if (small) {
    return '10px'
  }
  if (tiny) {
    return '5px'
  }
  return '20px'
}

export const SpacerVertical = ({ huge, large, small, tiny }) => {
  return <Block height={getSize({ large, small, tiny })} />
}

export const SpacerHorizontal = ({ huge, large, small, tiny }) => {
  return <Inline width={getSize({ large, small, tiny })} />
}
