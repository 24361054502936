import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block } from '@voicemachine/preact-ui'
import { setState } from '../actions'

class Player extends Component {
  onClick = (e) => {
    if (e.target === this.elem.base) {
      setState({ player: null })
    }
  }
  render() {
    const { player } = this.props
    if (!player) {
      return null
    }
    const { uri } = player
    return (
      <Block
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="rgba(0, 0, 0, 0.5)"
        ref={(elem) => (this.elem = elem)}
        onClick={this.onClick}
      >
        <audio controls autoplay src={uri} />
      </Block>
    )
  }
}

export default connect('player')(Player)
