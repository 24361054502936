import { h, Component } from 'preact'
import { Block, Row } from './blocks'
import { NotificationBall } from './NotificationBall'
import { Ripple } from './Ripple'

class Tab extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = () => {
    this.doAction()
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doAction(true)
    }
  }

  handleTouchStart = (e) => {
    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const touch = e.touches[0]
      var rect = this.base.getBoundingClientRect()
      const x = touch.pageX - rect.left
      const y = touch.pageY - rect.top
      const width = rect.width
      const height = rect.height
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    e.preventDefault()
    if (this.state.pressed) {
      this.doAction()
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  doAction = (removeFocus = false) => {
    const delay = this.state.ripples.length > 0 ? 300 : 0
    setTimeout(() => {
      this.props.onClick(this.props.option)
    }, delay)
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  render() {
    const { focus, ripples } = this.state
    const { isSelected, option, onClick } = this.props
    return (
      <Row
        role="tab"
        tabIndex="0"
        outline="none"
        background={focus ? 'rgba(234, 90, 153, 0.075)' : 'none'}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        ref={(elem) => (this.touchElem = elem)}
        // onTouchStart={this.handleTouchStart}
        // onTouchMove={this.handleTouchMove}
        // onTouchCancel={this.handleTouchCancel}
        // onTouchEnd={this.handleTouchEnd}
        height="50px"
        padding="0"
        fontSize="16px"
        lineHeight="18px"
        justifyContent="center"
        color={isSelected ? '#1f1f1f' : '#a7a7a7'}
        fill={isSelected ? '#1f1f1f' : '#a7a7a7'}
        borderBottom={isSelected ? 'solid 3px #1f1f1f' : 'solid 3px #f1f1f1'}
        flex="1"
        userSelect="none"
        WebkitUserSelect="none"
        cursor="pointer"
        position="relative"
        overflow="hidden"
      >
        {ripples.map((r) => (
          <Ripple {...r} onDone={this.rippleDone} />
        ))}
        <Block position="relative">
          {option.label}
          {option.notification && <NotificationBall top="-4px" right="-8px" />}
        </Block>
      </Row>
    )
  }
}

export const Tabs = ({ options, value, onChange }) => {
  const onClick = (option) => {
    if (option.value !== value) {
      onChange(option.value)
    }
  }

  return (
    <Row role="navigation">
      {options.map((option) => {
        const isSelected = option.value === value
        return <Tab isSelected={isSelected} option={option} onClick={onClick} />
      })}
    </Row>
  )
}
