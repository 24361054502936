import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Row,
  Block,
  SpacerVertical,
  ButtonTertiary,
  SeparatorVertical
} from '@voicemachine/preact-ui'

import { EditIcon } from '../../../icons'
import { route } from '../../../router'
import { setState } from '../../../actions'

import Missing from '../../Missing'

class VoiceDemos extends Component {
  play = (voiceDemo) => {
    setState({ player: { uri: voiceDemo.processedUri } })
  }

  render() {
    const { user } = this.props

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        {user.voiceDemos.length === 0 && <Missing>No voice demos</Missing>}
        {user.voiceDemos.map((voiceDemo, index) => {
          return (
            <Block padding="0 30px">
              <Row height="60px">
                <Block flex="1" onClick={() => this.play(voiceDemo)}>
                  {voiceDemo.types.join(', ')}
                </Block>
                <ButtonTertiary
                  onClick={() => route(`/users/${user.id}/voice-demos/${voiceDemo.id}`)}
                  icon={<EditIcon />}
                />
              </Row>
              {index < user.voiceDemos.length - 1 && <SeparatorVertical />}
            </Block>
          )
        })}
      </Block>
    )
  }
}

export default connect('user')(VoiceDemos)
