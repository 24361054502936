import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, SpacerVertical, ButtonTertiary } from '@voicemachine/preact-ui'

import { EditIcon } from '../../../icons'
import { route } from '../../../router'

import Missing from '../../Missing'

class Cards extends Component {
  render() {
    const { user } = this.props
    const cards = user.cards || []

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        {cards.length === 0 && <Missing>No credit cards</Missing>}
        {cards.map((card) => {
          return (
            <Row height="60px" margin="0 30px">
              <Block flex="1">
                {card.brand} •••• •••• •••• {card.last4} ({card.exp_month}/{card.exp_year})
              </Block>
              {card.primary && (
                <Block fontSize="12px" fontWeight="bold">
                  PRIMARY
                </Block>
              )}
              <ButtonTertiary
                onClick={() => route(`/users/${user.id}/cards/${card.id}`)}
                icon={<EditIcon />}
              />
            </Row>
          )
        })}
      </Block>
    )
  }
}

export default connect('user')(Cards)
