import { h, Component } from 'preact'

import { Row } from './blocks'
import { Input } from './Input'
import { Button } from './Button'

export class FileInput extends Component {
  render() {
    const { label, placeholder, accept, value, onChange, large = false } = this.props
    const filename = value ? value.name.split(/(\\|\/)/g).pop() : ''

    return (
      <Row alignItems="flex-end" paddingRight="30px">
        <Input
          type="text"
          label={label}
          value={filename}
          placeholder={placeholder}
          readonly
          onClick={() => this.fileInput.click()}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              this.fileInput.click()
            }
          }}
          large={large}
        />
        <Button onClick={() => this.fileInput.click()} flexShrink="0">
          Select file
        </Button>
        <input
          type="file"
          value={value}
          ref={(input) => (this.fileInput = input)}
          accept={accept}
          onChange={(e) => onChange(e.target.files[0])}
          style={{
            display: 'none'
          }}
        />
      </Row>
    )
  }
}
