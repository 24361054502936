import { h } from 'preact'

import {
  Block,
  Button,
  DeleteIcon,
  EditIcon,
  Input,
  Row,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  ToggleSwitch
} from '@voicemachine/preact-ui'
import { Component } from 'preact'
import { connect } from 'unistore/preact'

import {
  deleteProjectTemplateAddon,
  fetchProjectTemplate,
  updateProjectTemplate
} from '../../actions'
import { Switch, goBack, route } from '../../router'

import Avatar from '../Avatar'

import { InlineBlock } from 'jsxstyle/preact'
import CreateAddon from './CreateAddon'
import SelectUsers from './SelectUsers'

const getMaxWordOptions = (projectTemplate, numOptions = 11) => {
  const maxWordOptions = new Array(numOptions)
  maxWordOptions.fill({})
  if (Array.isArray(projectTemplate.maxWordOptions)) {
    for (let i = 0; i < projectTemplate.maxWordOptions.length; i++) {
      maxWordOptions[i] = projectTemplate.maxWordOptions[i]
    }
  }
  return maxWordOptions
}

class EditProjectTemplate extends Component {
  componentDidMount() {
    const { templateId } = this.props
    fetchProjectTemplate(templateId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectTemplate !== this.props.projectTemplate) {
      const { projectTemplate } = this.props
      this.setState({
        sequence: projectTemplate.sequence,
        name: projectTemplate.name || '',
        icon: projectTemplate.icon || '',
        description: projectTemplate.description || '',
        priceInfo: projectTemplate.priceInfo || '',
        moreInfoUrl: projectTemplate.moreInfoUrl || '',
        status: projectTemplate.status || '',
        voiceFee: projectTemplate.voiceFee || 0,
        customerPrice: projectTemplate.customerPrice || 0,
        voiceFeeText: projectTemplate.voiceFeeText || '',
        customerPriceText: projectTemplate.customerPriceText || '',
        licenseText: projectTemplate.licenseText || '',
        allowedClients: projectTemplate.allowedClients,
        allowedVoices: projectTemplate.allowedVoices,
        pickupAllowed: projectTemplate.pickupAllowed,
        pickupInfo: projectTemplate.pickupInfo,
        pickupDays: projectTemplate.pickupDays,
        pickupWords: projectTemplate.pickupWords,
        pickupVoiceFee: projectTemplate.pickupVoiceFee,
        pickupCustomerPrice: projectTemplate.pickupCustomerPrice,
        maxWordOptions: getMaxWordOptions(projectTemplate),
        hasScript: projectTemplate.hasScript,
        hasLineReader: projectTemplate.hasLineReader,
        hasMultipleNamedAudio: projectTemplate.hasMultipleNamedAudio,
        autoApproveDays: projectTemplate.autoApproveDays,
        requiresAdminApproval: projectTemplate.requiresAdminApproval,
        requiresAudio: projectTemplate.requiresAudio
      })
    }
  }

  handleSave = async () => {
    const params = {
      ...this.state,
      allowedClients: this.state.allowedClients.map((user) => user.id),
      allowedVoices: this.state.allowedVoices.map((user) => user.id),
      maxWordOptions: this.state.maxWordOptions.filter((option) => option.words !== undefined)
    }
    if (await updateProjectTemplate(params)) {
      goBack('/project-templates')
    }
  }

  deleteAddon = async (addon) => {
    if (confirm(`Delete addon ${addon.name}?`)) {
      await deleteProjectTemplateAddon(addon.id)
    }
  }

  updateMaxWordOption = (index, option) => {
    this.setState({
      maxWordOptions: this.state.maxWordOptions.map((o, i) => (i === index ? option : o))
    })
  }

  render() {
    const { templateId, projectTemplate, isUpdatingProjectTemplate } = this.props

    if (!projectTemplate || projectTemplate.id !== templateId) {
      return <Block padding="10px">Loading...</Block>
    }

    const {
      sequence,
      name,
      icon,
      description,
      moreInfoUrl,
      priceInfo,
      status,
      voiceFee,
      voiceFeeText,
      customerPrice,
      customerPriceText,
      licenseText,
      allowedClients = [],
      allowedVoices = [],
      pickupAllowed,
      pickupInfo,
      pickupDays,
      pickupWords,
      pickupVoiceFee,
      pickupCustomerPrice,
      maxWordOptions = getMaxWordOptions(projectTemplate),
      hasScript,
      hasLineReader,
      hasMultipleNamedAudio,
      autoApproveDays,
      requiresAdminApproval,
      requiresAudio
    } = this.state

    return (
      <Block padding="10px">
        <Input
          type="number"
          label="Sequence"
          value={sequence}
          onChange={(sequence) => this.setState({ sequence })}
        />
        <SpacerVertical />
        <Input label="Name" value={name} onChange={(name) => this.setState({ name })} />
        <SpacerVertical />
        <Input label="Icon" value={icon} onChange={(icon) => this.setState({ icon })} />
        <SpacerVertical />
        <TextArea
          rows={5}
          label="Description"
          value={description}
          onChange={(description) => this.setState({ description })}
        />
        <SpacerVertical />
        <Input
          label="Price info"
          value={priceInfo}
          onChange={(priceInfo) => this.setState({ priceInfo })}
        />
        <SpacerVertical />
        <Input
          label="More info URL"
          value={moreInfoUrl}
          onChange={(moreInfoUrl) => this.setState({ moreInfoUrl })}
        />
        <SpacerVertical />
        <Select
          label="Status"
          value={status}
          onChange={(status) => this.setState({ status })}
          options={[
            { value: 'enabled', label: 'Enabled' },
            { value: 'disabled', label: 'Disabled' }
          ]}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Customer price"
          value={customerPrice}
          onChange={(customerPrice) => this.setState({ customerPrice })}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Voice fee"
          value={voiceFee}
          onChange={(voiceFee) => this.setState({ voiceFee })}
        />
        <SpacerVertical />
        <TextArea
          rows={5}
          label="Customer price text"
          value={customerPriceText}
          onChange={(customerPriceText) => this.setState({ customerPriceText })}
        />
        <SpacerVertical />
        <TextArea
          rows={5}
          label="Voice fee text"
          value={voiceFeeText}
          onChange={(voiceFeeText) => this.setState({ voiceFeeText })}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Auto approve days"
          value={autoApproveDays}
          onChange={(autoApproveDays) => this.setState({ autoApproveDays })}
        />
        <SpacerVertical />
        {maxWordOptions.map((option, index) => {
          const { words, customerPrice, voiceFee } = option
          return (
            <Row alignItems="center">
              <Input
                type="number"
                label="Max words"
                value={words}
                onChange={(words) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
              />
              <Input
                type="number"
                label="Customer price"
                value={customerPrice}
                onChange={(customerPrice) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
              />
              <Input
                type="number"
                label="Voice fee"
                value={voiceFee}
                onChange={(voiceFee) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
              />
            </Row>
          )
        })}
        <SpacerVertical />
        <TextArea
          rows={15}
          label="License text"
          value={licenseText}
          onChange={(licenseText) => this.setState({ licenseText })}
        />
        <SpacerVertical large />
        <InlineBlock>
          <ToggleSwitch
            label="Has script"
            on={hasScript}
            onChange={(hasScript) => this.setState({ hasScript })}
          />
        </InlineBlock>
        <SpacerVertical large />
        <InlineBlock>
          <ToggleSwitch
            label="Has line reader"
            on={hasLineReader}
            onChange={(hasLineReader) => this.setState({ hasLineReader })}
          />
        </InlineBlock>
        <SpacerVertical large />
        <InlineBlock>
          <ToggleSwitch
            label="Requires admin approval"
            on={requiresAdminApproval}
            onChange={(requiresAdminApproval) => this.setState({ requiresAdminApproval })}
          />
        </InlineBlock>
        <SpacerVertical large />
        <InlineBlock>
          <ToggleSwitch
            label="Requires audio"
            on={requiresAudio}
            onChange={(requiresAudio) => this.setState({ requiresAudio })}
          />
        </InlineBlock>
        <SpacerVertical large />
        <InlineBlock>
          <ToggleSwitch
            label="Has multiple named audio"
            on={hasMultipleNamedAudio}
            onChange={(hasMultipleNamedAudio) => this.setState({ hasMultipleNamedAudio })}
          />
        </InlineBlock>
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Pickup allowed"
            on={pickupAllowed}
            onChange={(pickupAllowed) => this.setState({ pickupAllowed })}
          />
        </InlineBlock>
        <SpacerVertical />
        <Input
          label="Pickup info"
          value={pickupInfo}
          onChange={(pickupInfo) => this.setState({ pickupInfo })}
          disabled={!pickupAllowed}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Pickup days"
          value={pickupDays}
          onChange={(pickupDays) => this.setState({ pickupDays })}
          disabled={!pickupAllowed}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Pickup max words"
          value={pickupWords}
          onChange={(pickupWords) => this.setState({ pickupWords })}
          disabled={!pickupAllowed}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Pickup voice fee"
          value={pickupVoiceFee}
          onChange={(pickupVoiceFee) => this.setState({ pickupVoiceFee })}
          disabled={!pickupAllowed}
        />
        <SpacerVertical />
        <Input
          type="number"
          label="Pickup customer price"
          value={pickupCustomerPrice}
          onChange={(pickupCustomerPrice) => this.setState({ pickupCustomerPrice })}
          disabled={!pickupAllowed}
        />
        <SpacerVertical large />
        <Block padding="0 30px" fontSize="12px" color="rgb(167, 167, 167)">
          Allowed clients
        </Block>
        {allowedClients.length === 0 && <Block padding="0 30px">All clients allowed</Block>}
        {allowedClients.slice(0, 3).map((user) => {
          return (
            <Row alignItems="center" padding="5px 30px">
              <Avatar user={user} />
              <SpacerHorizontal small />
              <Block>
                <Block>{user.name}</Block>
                <Block fontSize="12px" color="#555">
                  {user.companyName}
                </Block>
              </Block>
            </Row>
          )
        })}
        {allowedClients.length > 3 && (
          <Block padding="0 30px">+ {allowedClients.length - 3} more</Block>
        )}
        <SpacerVertical />
        <Row padding="0 30px">
          <Button small onClick={() => route(`/project-templates/${templateId}/select-clients`)}>
            Select clients
          </Button>
        </Row>
        <SpacerVertical />
        <Block padding="0 30px" fontSize="12px" color="rgb(167, 167, 167)">
          Allowed voices
        </Block>
        {allowedVoices.length === 0 && <Block padding="0 30px">All voices allowed</Block>}
        {allowedVoices.slice(0, 3).map((user) => {
          return (
            <Row alignItems="center" padding="5px 30px">
              <Avatar user={user} />
              <SpacerHorizontal small />
              <Block>
                <Block>{user.name}</Block>
                <Block fontSize="12px" color="#555">
                  {user.companyName}
                </Block>
              </Block>
            </Row>
          )
        })}
        {allowedVoices.length > 3 && (
          <Block padding="0 30px">+ {allowedVoices.length - 3} more</Block>
        )}
        <SpacerVertical />
        <Row padding="0 30px">
          <Button small onClick={() => route(`/project-templates/${templateId}/select-voices`)}>
            Select voices
          </Button>
        </Row>
        <SpacerVertical />
        <Block padding="0 30px" fontSize="12px" color="rgb(167, 167, 167)">
          Addons
        </Block>
        <SpacerVertical />
        {projectTemplate.addons.map((addon) => {
          return (
            <Row padding="20px 30px" borderTop="solid 1px #eee">
              <Block flex="1">
                <Block>{addon.name}</Block>
                <Block color="#ccc" fontSize="14px">
                  {addon.description}
                </Block>
                <Block color="#ccc" fontSize="14px">
                  {addon.options.length} option{addon.options.length === 1 ? '' : 's'}
                </Block>
              </Block>
              <Button
                small
                onClick={() => route(`/project-templates/${templateId}/edit-addon/${addon.id}`)}
              >
                <EditIcon />
              </Button>
              <SpacerHorizontal />
              <Button small onClick={() => this.deleteAddon(addon)}>
                <DeleteIcon />
              </Button>
            </Row>
          )
        })}
        <SpacerVertical />
        <Row padding="0 30px">
          <Button small onClick={() => route(`/project-templates/${templateId}/create-addon`)}>
            New addon
          </Button>
        </Row>
        <SpacerVertical large />
        <Row alignItems="center" padding="0 30px">
          <Button primary loading={isUpdatingProjectTemplate} onClick={this.handleSave}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button onClick={() => goBack('/project-templates')}>Cancel</Button>
        </Row>
        <Switch>
          <SelectUsers
            userType="client"
            selected={allowedClients}
            onChange={(allowedClients) => this.setState({ allowedClients })}
            path="/project-templates/:templateId/select-clients"
          />
          <SelectUsers
            userType="voice"
            selected={allowedVoices}
            onChange={(allowedVoices) => this.setState({ allowedVoices })}
            path="/project-templates/:templateId/select-voices"
          />
          <CreateAddon path="/project-templates/:templateId/create-addon" />
          <CreateAddon path="/project-templates/:templateId/edit-addon/:addonId" />
        </Switch>
      </Block>
    )
  }
}

export default connect('projectTemplate, isUpdatingProjectTemplate')(EditProjectTemplate)
