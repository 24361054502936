import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import {
  Block,
  Button,
  Col,
  Input,
  Row,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  ToggleSwitch
} from '@voicemachine/preact-ui'

import { goBack } from '../../router'
import { createProjectTemplateAddon, updateProjectTemplateAddon } from '../../actions'

const getOptions = (addon, numOptions = 11) => {
  const options = new Array(numOptions)
  options.fill({})
  if (addon && Array.isArray(addon.options)) {
    for (let i = 0; i < addon.options.length; i++) {
      options[i] = addon.options[i]
    }
  }
  return options
}

class CreateAddon extends Component {
  state = {
    name: '',
    description: '',
    moreInfoUrl: '',
    options: getOptions()
  }

  componentDidMount() {
    const { addonId, projectTemplate } = this.props
    if (addonId) {
      const addon = projectTemplate.addons.find((addon) => addon.id === addonId)
      this.setState({
        name: addon.name,
        description: addon.description,
        moreInfoUrl: addon.moreInfoUrl,
        options: getOptions(addon)
      })
    }
  }

  updateOption = (index, option) => {
    this.setState({
      options: this.state.options.map((o, i) => (i === index ? option : o))
    })
  }

  toggleOption = (index, selected) => {
    if (selected) {
      this.setState({
        options: this.state.options.map((o, i) =>
          i === index ? { ...o, selected: true } : { ...o, selected: false }
        )
      })
    } else {
      this.setState({
        options: this.state.options.map((o, i) => (i === index ? { ...o, selected: false } : o))
      })
    }
  }

  handleSave = async () => {
    const params = {
      name: this.state.name,
      description: this.state.description || '',
      moreInfoUrl: this.state.moreInfoUrl || '',
      options: this.state.options.filter((option) => option.description !== undefined)
    }

    if (this.props.addonId) {
      if (await updateProjectTemplateAddon(this.props.addonId, params)) {
        goBack(`/project-templates/${this.props.templateId}`)
      }
    } else {
      params.templateId = this.props.templateId
      if (await createProjectTemplateAddon(params)) {
        goBack(`/project-templates/${this.props.templateId}`)
      }
    }
  }

  render() {
    const { templateId } = this.props
    const { name, description, moreInfoUrl, options } = this.state

    return (
      <Col
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.3)"
      >
        <Col width="500px" height="700px" maxHeight="100%" maxWidth="100%" background="white">
          <Block paddingTop="20px" flex="1" overflow="auto">
            <Input label="Name" value={name} onChange={(name) => this.setState({ name })} />
            <TextArea
              rows="5"
              label="Description"
              value={description}
              onChange={(description) => this.setState({ description })}
            />
            <Input
              label="More info URL"
              value={moreInfoUrl}
              onChange={(moreInfoUrl) => this.setState({ moreInfoUrl })}
            />
            <SpacerVertical />
            {options.map((option, index) => {
              const { description, customerPrice, voiceFee } = option
              return (
                <Block borderTop="solid 1px #eee">
                  <SpacerVertical />
                  <Block fontSize="14px" margin="0 30px">
                    Option {index + 1}
                  </Block>
                  <SpacerVertical small />
                  <ToggleSwitch
                    label="Selected"
                    on={option.selected}
                    onChange={(selected) => this.toggleOption(index, selected)}
                  />
                  <SpacerVertical small />
                  <Input
                    label="Description"
                    value={description}
                    onChange={(description) =>
                      this.updateOption(index, { description, customerPrice, voiceFee })
                    }
                  />
                  <Row alignItems="center">
                    <Input
                      type="number"
                      label="Customer price"
                      value={customerPrice}
                      onChange={(customerPrice) =>
                        this.updateOption(index, { description, customerPrice, voiceFee })
                      }
                    />
                    <Input
                      type="number"
                      label="Voice fee"
                      value={voiceFee}
                      onChange={(voiceFee) =>
                        this.updateOption(index, { description, customerPrice, voiceFee })
                      }
                    />
                  </Row>
                  <SpacerVertical />
                </Block>
              )
            })}
          </Block>
          <Row alignItems="center" padding="20px" borderTop="solid 1px #eee">
            <Button small onClick={this.handleSave}>
              Save
            </Button>
            <SpacerHorizontal small />
            <Button small onClick={() => goBack(`/project-templates/${templateId}`)}>
              Cancel
            </Button>
          </Row>
        </Col>
      </Col>
    )
  }
}

export default connect('projectTemplate, isCreatingAddon, isUpdatingAddon')(CreateAddon)
