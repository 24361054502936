import { h } from 'preact'
import { Block } from './blocks'

export const NotificationBall = (props) => (
  <Block
    position="absolute"
    background="#EA5A99"
    top="0"
    right="0"
    width="10px"
    height="10px"
    borderRadius="50%"
    {...props}
  />
)
