import { h } from 'preact'

import { Block, Col } from './blocks'
import { Paragraph } from './Paragraph'

const processLine = (line) => {
  let l = line
  // Keep spaces in beginning
  l = l.replace(/^ /g, '\u00a0')
  // Handle rest of spaces
  // Keep regular spaces to make lines wrap
  l = l.replace(/  /g, ' \u00a0')
  // Handle tabs
  l = l.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0')
  //
  return l
}

export const TextAreaView = ({ label, value, placeholder, minHeight, large = false, onClick }) => {
  const lines = value ? value.split('\n') : undefined
  const fontSize = large ? '20px' : '16px'
  const lineHeight = large ? '24px' : '18px'
  const labelFontSize = large ? '15px' : '12px'
  const labelLineHeight = large ? '24px' : '18px'
  return (
    <Col width="100%" fontSize={fontSize} onClick={onClick}>
      {label && (
        <Block
          padding="10px 30px 5px 30px"
          fontSize={labelFontSize}
          lineHeight={labelLineHeight}
          color="#a7a7a7"
        >
          {label}
        </Block>
      )}
      {!value && (
        <Block flex="1">
          <Paragraph
            color="#a9a9a9"
            fontSize={fontSize}
            lineHeight={lineHeight}
            minHeight={minHeight}
          >
            {placeholder}
          </Paragraph>
        </Block>
      )}
      {value && (
        <Block flex="1">
          {lines.map((l) => (
            <Paragraph fontSize={fontSize} lineHeight={lineHeight} minHeight={lineHeight}>
              {processLine(l)}
            </Paragraph>
          ))}
        </Block>
      )}
    </Col>
  )
}
