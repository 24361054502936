import { h, Component } from 'preact'

import { Block, Row } from './blocks'
import { Link } from './Link'
import { IconButton } from './IconButton'
import { SpacerHorizontal } from './spacers'
import { Ripple } from './Ripple'

export class ToggleSwitch extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = () => {
    this.doAction()
  }

  handleMoreClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onMore) {
      this.props.onMore()
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      this.doAction(false)
    }
  }

  handleTouchStart = (e) => {
    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const touch = e.touches[0]
      var rect = this.base.getBoundingClientRect()
      const x = touch.pageX - rect.left
      const y = touch.pageY - rect.top
      const width = rect.width
      const height = rect.height
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    e.preventDefault()
    if (this.state.pressed) {
      this.doAction()
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  doAction = (removeFocus = false) => {
    const { onChange, on } = this.props
    onChange && onChange(!on)
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  render() {
    const { focus, ripples } = this.state
    const { on, label, moreIcon, onMore, large = false } = this.props

    const height = large ? '75px' : '60px'
    const focusHeight = large ? '52px' : '45px'
    const width = large ? '56px' : '45px'
    const focusWidth = large ? '76px' : '65px'
    const radius = large ? '24px' : '19px'
    const focusRadius = large ? '44px' : '39px'
    const padding = large ? '4px' : '3px'
    const circleSize = large ? '24px' : '19px'
    const fontSize = large ? '20px' : '16px'
    const lineHeight = large ? '24px' : '20px'

    return (
      <Row
        height={height}
        padding="0 30px"
        alignItems="center"
        position="relative"
        overflow="hidden"
      >
        {ripples.map((r) => (
          <Ripple {...r} onDone={this.rippleDone} />
        ))}

        <Row
          height="100%"
          onClick={this.handleClick}
          ref={(elem) => (this.touchElem = elem)}
          // onTouchStart={this.handleTouchStart}
          // onTouchMove={this.handleTouchMove}
          // onTouchCancel={this.handleTouchCancel}
          // onTouchEnd={this.handleTouchEnd}
          cursor="pointer"
          flex="1"
        >
          <Block
            margin="-10px"
            padding="10px"
            background={focus ? 'rgba(234, 90, 153, 0.075)' : 'none'}
            width={focusWidth}
            height={focusHeight}
            borderRadius={focusRadius}
          >
            <Row
              width={width}
              padding={padding}
              background={on ? '#ea5a99' : '#D1D2D4'}
              justifyContent={on ? 'flex-end' : 'flex-start'}
              borderRadius={radius}
              flexShrink="0"
              tabIndex="0"
              outline="none"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyDown={this.handleKeyDown}
              zIndex="1"
            >
              <Block
                width={circleSize}
                height={circleSize}
                background="#f7f7f7"
                borderRadius="50%"
              />
            </Row>
          </Block>
          <Row flex="1">
            {label && (
              <Block
                flex="1"
                fontSize={fontSize}
                lineHeight={lineHeight}
                paddingLeft="15px"
                userSelect="none"
                WebkitUserSelect="none"
              >
                {label}
              </Block>
            )}
          </Row>
        </Row>
        {label && moreIcon && onMore && <SpacerHorizontal small />}
        {moreIcon && onMore && (
          <IconButton onClick={this.handleMoreClick} icon={moreIcon} large={large} />
        )}
      </Row>
    )
  }
}
