import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Block, Button, Col, Input, Row, SpacerHorizontal } from '@voicemachine/preact-ui'

import { goBack } from '../../../router'
import { get } from '../../../api'

import Avatar from '../../Avatar'
import { updateProject } from '../../../actions'

class SelectClient extends Component {
  componentDidMount() {
    const url = `/users?status=verified&voiceStatus=client`
    get(url).then(({ data: users }) => this.setState({ users }))
  }

  selectClient = async (user) => {
    console.log('Select', user, this.props)
    if (await updateProject(this.props.projectId, { userId: user.id })) {
      goBack(`/projects/${this.props.projectId}/details`)
    }
  }

  render() {
    const { users = [], query = '' } = this.state

    const regex = new RegExp(`\\b${query}`, 'i')
    const filteredUsers = users.filter((user) => {
      return query.trim() === '' || regex.test(user.name) || regex.test(user.companyName)
    })
    return (
      <Col
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.3)"
      >
        <Col width="500px" height="700px" maxHeight="100%" maxWidth="100%" background="white">
          <Block paddingTop="20px" borderBottom="solid 1px #eee">
            <Input label="Filter" value={query} onChange={(query) => this.setState({ query })} />
          </Block>
          <Block flex="1" overflow="auto">
            {users.length === 0 && <Block padding="20px">Loading...</Block>}
            {filteredUsers.map((user) => {
              return (
                <Row
                  alignItems="center"
                  height="60px"
                  paddingLeft="25px"
                  cursor="pointer"
                  hoverBackground="#eee"
                  props={{ onClick: () => this.selectClient(user) }}
                >
                  <Avatar user={user} />
                  <SpacerHorizontal small />
                  <Block>
                    <Block>{user.name}</Block>
                    <Block fontSize="12px" color="#555">
                      {user.companyName}
                    </Block>
                  </Block>
                </Row>
              )
            })}
          </Block>
          <Row alignItems="center" padding="20px" borderTop="solid 1px #eee">
            <Button
              small
              loading={this.props.isUpdatingProject}
              onClick={() => goBack(`/projects/${this.props.projectId}/details`)}
            >
              Cancel
            </Button>
          </Row>
        </Col>
      </Col>
    )
  }
}

export default connect('isUpdatingProject')(SelectClient)
