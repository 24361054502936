import { h, Component } from 'preact'

import { Switch } from '../../router'

import SearchUsers from './SearchUsers'
import User from './User'

class Users extends Component {
  render() {
    return (
      <Switch>
        <User path="/users/:userId" />
        <SearchUsers />
      </Switch>
    )
  }
}

export default Users
