import { h } from 'preact'

import { Block } from './blocks'

export const Section = ({ children, large = false, ...styles }) => {
  const minHeight = large ? '90px' : '60px'
  const fontSize = large ? '18px' : '15px'
  const lineHeight = large ? '23px' : '18px'
  const letterSpacing = large ? '0.75px' : '0.5px'
  return (
    <Block
      minHeight={minHeight}
      display="flex"
      alignItems="center"
      padding="20px 30px"
      fontSize={fontSize}
      lineHeight={lineHeight}
      textTransform="uppercase"
      letterSpacing={letterSpacing}
      {...styles}
    >
      {children}
    </Block>
  )
}
