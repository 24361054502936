import { h } from 'preact'

import {
  Block,
  Button,
  DeleteIcon,
  EditIcon,
  Input,
  ProfilePicture,
  Row,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  ToggleSwitch
} from '@voicemachine/preact-ui'
import { InlineBlock } from 'jsxstyle/preact'
import { Component } from 'preact'
import { connect } from 'unistore/preact'

import { deleteProjectAddon, updateProject } from '../../../actions'

import { languageOptions } from '../../../languages'
import { Switch, route } from '../../../router'
import SelectClient from './SelectClient'

const getMaxWordOptions = (project, numOptions = 11) => {
  const maxWordOptions = new Array(numOptions)
  maxWordOptions.fill({})
  if (Array.isArray(project.maxWordOptions)) {
    for (let i = 0; i < project.maxWordOptions.length; i++) {
      maxWordOptions[i] = project.maxWordOptions[i]
    }
  }
  return maxWordOptions
}

class ProjectDetails extends Component {
  state = {
    name: this.props.project.name,
    language: this.props.project.language,
    type: this.props.project.type,
    description: this.props.project.description || '',
    customerPrice: this.props.project.customerPrice,
    customerPriceText: this.props.project.customerPriceText || '',
    voiceFee: this.props.project.voiceFee,
    voiceFeeText: this.props.project.voiceFeeText || '',
    licenseText: this.props.project.licenseText,
    dueDate: this.props.project.dueDate || '',
    maxWords: this.props.project.maxWords,
    maxWordOptions: getMaxWordOptions(this.props.project),
    notes: this.props.project.notes || '',
    hasScript: this.props.project.hasScript,
    hasLineReader: this.props.project.hasLineReader,
    requiresAdminApproval: this.props.project.requiresAdminApproval,
    requiresAudio: this.props.project.requiresAudio,
    hasMultipleNamedAudio: this.props.project.hasMultipleNamedAudio,
    autoApproveDays: this.props.project.autoApproveDays,
    purchaseOrder: this.props.project.purchaseOrder || '',
    maxVoices: this.props.project.maxVoices
  }

  save = () => {
    let params
    const assignedVoice = this.props.project.voices.find((voice) => voice.isAssigned)
    if (assignedVoice) {
      params = {
        name: this.state.name,
        notes: this.state.notes,
        autoApproveDays: this.state.autoApproveDays,
        voiceFee: this.state.voiceFee,
        voiceFeeText: this.state.voiceFeeText,
        purchaseOrder: this.state.purchaseOrder,
        requiresAudio: this.state.requiresAudio
      }
    } else {
      params = {
        ...this.state,
        maxWordOptions: this.state.maxWordOptions.filter((option) => option.words !== undefined)
      }
    }
    updateProject(this.props.projectId, params)
  }

  updateMaxWordOption = (index, option) => {
    this.setState({
      maxWordOptions: this.state.maxWordOptions.map((o, i) => (i === index ? option : o))
    })
  }

  deleteAddon = async (addon) => {
    if (confirm(`Delete addon ${addon.name}?`)) {
      await deleteProjectAddon(addon.id)
    }
  }

  render() {
    const { project, isUpdatingProject } = this.props

    const assignedVoice = project.voices.find((voice) => voice.isAssigned)

    const {
      name,
      language,
      description,
      customerPrice,
      customerPriceText,
      voiceFee,
      voiceFeeText,
      licenseText,
      dueDate,
      maxWords,
      maxWordOptions,
      notes,
      hasScript,
      hasLineReader,
      requiresAdminApproval,
      requiresAudio,
      hasMultipleNamedAudio,
      autoApproveDays,
      purchaseOrder,
      maxVoices
    } = this.state

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        {assignedVoice && (
          <Block border="solid 3px red" margin="30px" padding="20px">
            Project is locked because there is an assigned voice. You can only edit a limited number
            of settings.
          </Block>
        )}
        {!assignedVoice && <SpacerVertical />}
        <TextArea
          label="Notes"
          resizable={false}
          rows="4"
          value={notes}
          onChange={(notes) => this.setState({ notes })}
        />
        <SpacerVertical />
        <Block padding="0 30px" marginBottom="10px" fontSize="12px" color="rgb(167, 167, 167)">
          Client
        </Block>
        <Row padding="0 30px">
          {project.client.picture && [
            <ProfilePicture size="32" src={project.client.picture} />,
            <SpacerHorizontal />
          ]}
          <Block>
            {project.client.name}
            <Block fontSize="12px">
              <a href={`mailto:${project.client.email}`}>{project.client.email}</a>
            </Block>
          </Block>
          <SpacerHorizontal />
          <Button
            small
            onClick={() => route(`/projects/${this.props.projectId}/details/select-client`)}
          >
            Change client
          </Button>
        </Row>
        {assignedVoice && (
          <Block>
            <SpacerVertical />
            <Block padding="0 30px" marginBottom="10px" fontSize="12px" color="rgb(167, 167, 167)">
              Assigned voice
            </Block>
            <Row padding="0 30px">
              {assignedVoice.picture && [
                <ProfilePicture size="32" src={assignedVoice.picture} />,
                <SpacerHorizontal />
              ]}
              <Block>
                {assignedVoice.name}
                <Block fontSize="12px">
                  <a href={`mailto:${assignedVoice.email}`}>{assignedVoice.email}</a>
                </Block>
              </Block>
            </Row>
          </Block>
        )}
        <SpacerVertical />
        <Input label="Name" value={name} onChange={(name) => this.setState({ name })} />
        <SpacerVertical />
        <Select
          label="Language"
          value={language}
          onChange={(language) => this.setState({ language })}
          options={languageOptions}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <Input
          label="Due date"
          value={dueDate}
          onChange={(dueDate) => this.setState({ dueDate })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <Input
          label="Purchase order number / reference"
          value={purchaseOrder}
          onChange={(purchaseOrder) => this.setState({ purchaseOrder })}
        />
        <SpacerVertical />
        <TextArea
          rows="5"
          label="Description"
          value={description}
          onChange={(description) => this.setState({ description })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <Select
          label="Max invited voices"
          value={maxVoices}
          onChange={(maxVoices) => this.setState({ maxVoices })}
          options={[
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 },
            { value: 6, label: 6 }
          ]}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <Input
          label="Customer price in EUR"
          type="number"
          value={customerPrice}
          onChange={(customerPrice) => this.setState({ customerPrice })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <TextArea
          rows="5"
          label="Customer price comment"
          value={customerPriceText}
          onChange={(customerPriceText) => this.setState({ customerPriceText })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <Input
          label="Voice fee in EUR"
          type="number"
          value={voiceFee}
          onChange={(voiceFee) => this.setState({ voiceFee })}
        />
        <SpacerVertical />
        <TextArea
          rows="5"
          label="Voice fee comment"
          value={voiceFeeText}
          onChange={(voiceFeeText) => this.setState({ voiceFeeText })}
        />
        <SpacerVertical />
        <Input
          label="Auto approve days (set to 0 to disable)"
          type="number"
          value={autoApproveDays}
          onChange={(autoApproveDays) => this.setState({ autoApproveDays })}
        />
        <SpacerVertical />
        <Input
          label="Max words (selected)"
          type="number"
          value={maxWords}
          onChange={(maxWords) => this.setState({ maxWords })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        {maxWordOptions.map((option, index) => {
          const { words, customerPrice, voiceFee } = option
          return (
            <Row alignItems="center">
              <Input
                type="number"
                label="Max words option"
                value={words}
                onChange={(words) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
                disabled={assignedVoice}
              />
              <Input
                type="number"
                label="Customer price"
                value={customerPrice}
                onChange={(customerPrice) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
                disabled={assignedVoice}
              />
              <Input
                type="number"
                label="Voice fee"
                value={voiceFee}
                onChange={(voiceFee) =>
                  this.updateMaxWordOption(index, { words, customerPrice, voiceFee })
                }
                disabled={assignedVoice}
              />
            </Row>
          )
        })}
        <SpacerVertical />
        <Block padding="0 30px" fontSize="12px" color="rgb(167, 167, 167)">
          Addons
        </Block>
        <SpacerVertical />
        {project.addons.map((addon) => {
          return (
            <Row padding="20px 30px" borderTop="solid 1px #eee">
              <Block flex="1">
                <Block>{addon.name}</Block>
                <Block color="#ccc" fontSize="14px">
                  {addon.description}
                </Block>
                <Block color="#ccc" fontSize="14px">
                  {addon.options.length} option{addon.options.length === 1 ? '' : 's'}
                </Block>
              </Block>
              <Button
                small
                disabled={assignedVoice}
                onClick={() => route(`/projects/${project.id}/edit-addon/${addon.id}`)}
              >
                <EditIcon />
              </Button>
              <SpacerHorizontal />
              <Button small disabled={assignedVoice} onClick={() => this.deleteAddon(addon)}>
                <DeleteIcon />
              </Button>
            </Row>
          )
        })}
        <SpacerVertical />
        <Row padding="0 30px">
          <Button
            small
            disabled={assignedVoice}
            onClick={() => route(`/projects/${project.id}/create-addon`)}
          >
            New addon
          </Button>
        </Row>
        <SpacerVertical large />
        <TextArea
          rows="5"
          label="License text"
          value={licenseText}
          onChange={(licenseText) => this.setState({ licenseText })}
          disabled={assignedVoice}
        />
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Has script"
            on={hasScript}
            onChange={assignedVoice ? undefined : (hasScript) => this.setState({ hasScript })}
          />
        </InlineBlock>
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Has line reader"
            on={hasLineReader}
            onChange={
              assignedVoice ? undefined : (hasLineReader) => this.setState({ hasLineReader })
            }
          />
        </InlineBlock>
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Requires admin approval"
            on={requiresAdminApproval}
            onChange={
              assignedVoice
                ? undefined
                : (requiresAdminApproval) => this.setState({ requiresAdminApproval })
            }
          />
        </InlineBlock>
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Requires audio"
            on={requiresAudio}
            onChange={(requiresAudio) => this.setState({ requiresAudio })}
          />
        </InlineBlock>
        <SpacerVertical />
        <InlineBlock>
          <ToggleSwitch
            label="Has multiple named audio"
            on={hasMultipleNamedAudio}
            onChange={
              assignedVoice
                ? undefined
                : (hasMultipleNamedAudio) => this.setState({ hasMultipleNamedAudio })
            }
          />
        </InlineBlock>
        <SpacerVertical />
        <Row justifyContent="center">
          <Button primary loading={isUpdatingProject} onClick={this.save}>
            Save
          </Button>
        </Row>
        <SpacerVertical large />
        <Switch>
          <SelectClient
            onChange={(client) => this.setState({ clientId: client.id })}
            path="/projects/:projectId/details/select-client"
          />
        </Switch>
      </Block>
    )
  }
}

export default connect('project, isUpdatingProject')(ProjectDetails)
