import { h, Component } from 'preact'

import { Block } from './blocks'

const style = {
  ul: {
    fontSize: '16px',
    lineHeight: '20px',
    padding: '0 30px 0 30px'
  },
  li: {
    marginLeft: '30px',
    paddingBottom: '20px'
  }
}

export const ListItem = ({ children }) => (
  <Block display="list-item" component="li" marginLeft="30px" paddingBottom="20px">
    {children}
  </Block>
)

export const List = ({ children, large = false }) => {
  return (
    <Block
      component="ul"
      fontSize={large ? '20px' : '16px'}
      lineHeight={large ? '24px' : '18px'}
      padding="0 30px"
    >
      {children}
    </Block>
  )
}
