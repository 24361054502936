import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Loading } from '@voicemachine/preact-ui'

import { Switch, Redirect } from '../../../router'
import { fetchProject } from '../../../actions'

import ProjectMenu from './ProjectMenu'
import ProjectDetails from './ProjectDetails'
import Voices from './Voices'
import Files from './Files'
import CreateAddon from './CreateAddon'
import ProjectChat from './ProjectChat'
import ProjectChats from './ProjectChats'

class Project extends Component {
  componentWillMount() {
    fetchProject(this.props.projectId)
  }

  render() {
    if (!this.props.project || this.props.project.id !== this.props.projectId) {
      return (
        <Row flex="1" justifyContent="center" alignItems="center" height="80vh">
          <Loading stroke="#000" />
        </Row>
      )
    }

    return (
      <Row flex="1" alignItems="flex-start">
        <ProjectMenu />
        <Switch>
          <ProjectDetails path="/projects/:projectId/details" />
          <Voices path="/projects/:projectId/voices" />
          <Files path="/projects/:projectId/files" />
          <CreateAddon path="/projects/:projectId/create-addon" />
          <CreateAddon path="/projects/:projectId/edit-addon/:addonId" />
          <ProjectChat path="/projects/:projectId/chat/:voiceId" />
          <ProjectChats path="/projects/:projectId/chat" />
          <Redirect to={`/projects/${this.props.projectId}/details`} />
        </Switch>
      </Row>
    )
  }
}

export default connect('project')(Project)
