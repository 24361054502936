import { h, render } from 'preact'
import { Provider } from 'unistore/preact'

import App from './App'

import './index.css'
import { store } from './store'

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.body,
  document.body.lastChild
)
