import { h, Component } from 'preact'
import { Block, InlineBlock } from './blocks'
import { Loading } from './Loading'
import { Ripple } from './Ripple'

const getColor = ({ disabled }) => {
  if (disabled) {
    return 'rgba(0, 0, 0, 0.25)'
  }
  return '#1f1f1f'
}

const getFill = ({ disabled }) => {
  if (disabled) {
    return 'rgba(0, 0, 0, 0.20)'
  }
  return '#444'
}

const getBackground = ({ disabled, focus }) => {
  if (focus) {
    return '#F9EEF2'
  }
  return '#fafafa'
}

export class FloatingButton extends Component {
  state = {
    focus: false,
    ripples: [],
    pressed: false
  }

  componentDidMount() {
    this.touchElem.base.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    this.touchElem.base.addEventListener('touchmove', this.handleTouchMove, { passive: true })
    this.touchElem.base.addEventListener('touchcancel', this.handleTouchCancel, { passive: true })
    this.touchElem.base.addEventListener('touchend', this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.touchElem.base.removeEventListener('touchstart', this.handleTouchStart)
    this.touchElem.base.removeEventListener('touchmove', this.handleTouchMove)
    this.touchElem.base.removeEventListener('touchcancel', this.handleTouchCancel)
    this.touchElem.base.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleFocus = () => this.setState({ focus: true })

  handleBlur = () => this.setState({ focus: false })

  handleClick = () => {
    this.doAction()
  }

  doAction = (removeFocus = false) => {
    if (this.state.ripples.length > 0) {
      setTimeout(() => {
        this.props.onClick()
      }, 300)
    } else {
      this.props.onClick()
    }
    if (removeFocus) {
      this.setState({ focus: false })
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.doAction(false)
    }
  }

  handleTouchStart = (e) => {
    if (this.props.disabled || !this.props.onClick) {
      return
    }

    // Ignore multi touch
    if (e.touches.length === 1) {
      this.setState({ pressed: true })
      const touch = e.touches[0]
      var rect = this.base.getBoundingClientRect()
      const x = touch.pageX - rect.left
      const y = touch.pageY - rect.top
      const width = rect.width
      const height = rect.height
      this.startRipple(x, y, width, height)
    }
  }

  handleTouchMove = (e) => {
    const touch = e.touches[0]
    if (this.base !== document.elementFromPoint(touch.pageX, touch.pageY)) {
      this.cancelRipple()
    }
  }

  handleTouchCancel = (e) => {
    this.cancelRipple()
  }

  handleTouchEnd = (e) => {
    e.preventDefault()
    if (this.state.pressed) {
      this.doAction()
    }
    const ripples = [...this.state.ripples].filter((ripple) => {
      return !ripple.done
    })
    this.setState({ ripples, focus: false, pressed: false })
  }

  startRipple = (x, y, width, height) => {
    const ripples = [...this.state.ripples]
    ripples.push({ x, y, width, height, done: false })
    this.setState({ ripples })
  }

  cancelRipple = () => {
    this.setState({ ripples: [], focus: false, pressed: false })
  }

  rippleDone = () => {
    const ripples = [...this.state.ripples]
    if (ripples.length > 1) {
      ripples.shift()
      this.setState({ ripples, focus: false })
    } else if (ripples.length === 1) {
      if (!this.state.pressed) {
        this.cancelRipple()
      } else {
        ripples[0].done = true
        this.setState({ ripples })
      }
    }
  }

  render() {
    const { focus, ripples } = this.state
    const { children, disabled, loading, onClick, icon, large, ...props } = this.props

    const clickable = !disabled && !loading && onClick
    const webkitTapHighlightColor = !clickable ? 'rgba(0 , 0, 0, 0)' : ''
    const background = getBackground({ disabled, focus })
    const color = getColor({ disabled })
    const fill = getFill({ disabled })

    const height = large ? '63px' : '50px'
    const fontSize = large ? '18px' : '14px'
    const lineHeight = large ? '20px' : '16px'
    const letterSpacing = large ? '0.75px' : '0.5px'
    const padding = large ? '0 25px 0 15px' : '0 20px 0 12px'
    const iconSpacing = large ? '7px' : '5px'

    return (
      <Block
        component="button"
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        ref={(elem) => (this.touchElem = elem)}
        // onTouchStart={this.handleTouchStart}
        // onTouchMove={this.handleTouchMove}
        // onTouchCancel={this.handleTouchCancel}
        // onTouchEnd={this.handleTouchEnd}
        position="fixed"
        bottom="80px"
        marginBottom="env(safe-area-inset-bottom)"
        right="20px"
        overflow="hidden"
        textOverflow="ellipsis"
        disabled={disabled}
        onClick={clickable ? this.handleClick : null}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={'normal'}
        textTransform="uppercase"
        letterSpacing={letterSpacing}
        background={background}
        color={color}
        padding={padding}
        height={height}
        borderRadius="3px"
        boxShadow="0 3px 12px rgba(0, 0, 0, 0.33)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor={clickable ? 'pointer' : ''}
        webkitTapHighlightColor={webkitTapHighlightColor}
        {...props}
      >
        {ripples.map((r) => (
          <Ripple {...r} onDone={this.rippleDone} />
        ))}
        {icon && (
          <InlineBlock
            marginRight={!loading && children.length > 0 ? iconSpacing : null}
            fill={fill}
          >
            {icon}
          </InlineBlock>
        )}
        {loading && <Loading stroke={fill} />}
        {!loading && children}
      </Block>
    )
  }
}
