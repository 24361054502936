import { h } from 'preact'

import { Block } from './blocks'

export const Paragraph = ({ children, large = false, ...props }) => (
  <Block
    component="p"
    fontSize={large ? '20px' : '16px'}
    lineHeight={large ? '24px' : '18px'}
    margin="0"
    padding="0 30px"
    {...props}
  >
    {children}
  </Block>
)
