import { h, Fragment } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, InlineBlock, SpacerHorizontal } from '@voicemachine/preact-ui'

import { route } from '../router'
import { logout } from '../actions'

const items = [
  { path: '/dashboard', label: 'Home' },
  { path: '/users', label: 'Users' },
  { path: '/projects', label: 'Projects' },
  { path: '/project-templates', label: 'Project templates' },
  { path: '/manual-invoices', label: 'Invoices' },
  { path: '/api-keys', label: 'API keys' }
]

const MenuItem = ({ path, label }) => {
  const isActive = window.location.pathname.startsWith(path)
  const onClick = () => route(path)
  return (
    <InlineBlock
      height="100%"
      padding="10px"
      cursor="pointer"
      color="#fff"
      borderBottom={isActive ? 'solid 4px #fff' : ''}
      onClick={onClick}
      userSelect="none"
    >
      {label}
    </InlineBlock>
  )
}

const AdminUser = ({ email }) => {
  return (
    <Block color="#fff">
      <InlineBlock opacity="0.5">{email}</InlineBlock>
      <SpacerHorizontal tiny />
      <InlineBlock userSelect="none" padding="10px" cursor="pointer" onClick={() => logout()}>
        Logout
      </InlineBlock>
    </Block>
  )
}

const AppMenu = ({ email }) => {
  return (
    <Row
      background="#EA5A99"
      flexShrink="0"
      alignItems="baseline"
      justifyContent={email ? 'space-between' : 'flex-end'}
    >
      {!email && (
        <Block color="#fff" padding="10px" borderBottom="solid 4px rgba(0, 0, 0, 0) ">
          Logging in…
        </Block>
      )}
      {email && (
        <Block>
          {items.map((item) => (
            <MenuItem {...item} />
          ))}
        </Block>
      )}
      {email && <AdminUser email={email} />}
    </Row>
  )
}

export default connect('email')(AppMenu)
