import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Block, Select, Button, SpacerVertical } from '@voicemachine/preact-ui'

import { updateUser } from '../../../actions'

const notificationOptions = [{ value: 'on', label: 'On' }, { value: 'off', label: 'Off' }]

const val = (v) => (v ? 'on' : 'off')

class EditNotifications extends Component {
  state = {
    emailNotifications: val(this.props.user.emailNotifications),
    pushNotifications: val(this.props.user.pushNotifications)
  }

  save = () => {
    updateUser(this.props.userId, {
      emailNotifications: this.state.emailNotifications === 'on',
      pushNotifications: this.state.pushNotifications === 'on'
    })
  }

  render() {
    const { user, isUpdatingUser } = this.props
    const { emailNotifications, pushNotifications } = this.state

    const hasChanges =
      emailNotifications !== val(user.emailNotifications) ||
      pushNotifications !== val(user.pushNotifications)

    return (
      <Block flex="1" height="100%" background="#FAFAFA">
        <SpacerVertical />
        <Select
          label="Email notifications"
          options={notificationOptions}
          value={emailNotifications}
          onChange={(emailNotifications) => this.setState({ emailNotifications })}
        />
        <SpacerVertical />
        <Select
          label="Push notifications"
          options={notificationOptions}
          value={pushNotifications}
          onChange={(pushNotifications) => this.setState({ pushNotifications })}
        />
        <SpacerVertical />
        <Row justifyContent="center">
          <Button primary loading={isUpdatingUser} disabled={!hasChanges} onClick={this.save}>
            Save
          </Button>
        </Row>
      </Block>
    )
  }
}

export default connect('user, isUpdatingUser')(EditNotifications)
