import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { SpacerVertical, Block } from '@voicemachine/preact-ui'
import { get } from '../../../api'
import { route } from '../../../router'

class ProjectChat extends Component {
  componentDidMount = () => {
    const project = this.props.project
    get(`/projects/${project.id}/voices`).then(({ data: voices }) => this.setState({ voices }))
  }

  render() {
    const { voices } = this.state

    if (!voices) {
      return <div>Loading chats...</div>
    }

    const projectVoices = this.props.project.voices

    return (
      <div>
        <h1>Chats</h1>
        {voices.map((voice) => {
          const readOnly = projectVoices.every((v) => v.id !== voice.id)
          return (
            <Block
              padding="5px"
              cursor="pointer"
              hoverFontWeight="bold"
              onClick={() => route(`/projects/${this.props.project.id}/chat/${voice.id}`)}
            >
              {voice.name} {readOnly && <span style="color:red">(Read only)</span>}
            </Block>
          )
        })}
        <SpacerVertical large />
      </div>
    )
  }
}

export default connect('project, messages')(ProjectChat)
