import { h, Component } from 'preact'
import { connect } from 'unistore/preact'
import { Row, Loading, SpacerVertical } from '@voicemachine/preact-ui'

import { Switch, Redirect } from '../../../router'
import { fetchUser } from '../../../actions'

import UserMenu from './UserMenu'
import EditUser from './EditUser'
import EditNotifications from './EditNotifications'
import Cards from './Cards'
import VoiceDemos from './VoiceDemos'
import EditVoiceDemo from './EditVoiceDemo'
import StudioDemo from './StudioDemo'
import Projects from './Projects'
import AddLabel from './AddLabel'

class User extends Component {
  componentWillMount() {
    fetchUser(this.props.userId)
  }

  render() {
    if (!this.props.user || this.props.user.id !== this.props.userId) {
      return (
        <Row flex="1" justifyContent="center" alignItems="center" height="80vh">
          <Loading stroke="#000" />
        </Row>
      )
    }

    return (
      <Row flex="1" flexShrink="0" alignItems="normal">
        <UserMenu />
        <Switch>
          <EditUser path="/users/:userId/details" />
          <EditNotifications path="/users/:userId/notifications" />
          <AddLabel path="/users/:userId/add-label" />
          <Cards path="/users/:userId/cards" />
          <EditVoiceDemo path="/users/:userId/voice-demos/:voiceDemoId" />
          <VoiceDemos path="/users/:userId/voice_demos" />
          <StudioDemo path="/users/:userId/studio_demo" />
          <Projects path="/users/:userId/projects" />
          <Redirect to={`/users/${this.props.userId}/details`} />
        </Switch>
      </Row>
    )
  }
}

export default connect('user, isFetchingUser')(User)
